import React, { useEffect, useState } from 'react';
import AuthService from '../../../API/AuthService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../API/AuthContext";
import { Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography, Alert, useMediaQuery, InputAdornment, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import backgroundImage from './background.jpg';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, login } = useAuth();
    const [rememberMe, setRememberMe] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleRegisterClick = () => {
        if (isAuthenticated) {
            navigate('/home');
        } else {
            navigate('/register');
        }
    };

    const handleForgotPassword = () => {
        if(!isAuthenticated)
            navigate('/password');
    }

    const handleSignIn = async () => {
        setErrorMessage('');

        try {
            const response = await AuthService.signIn(email, password);
            if (response.token) {
                login(response.token, { userId: response.userId, userName: response.userName, accountId: response.accountId, role: response.role }, rememberMe);
                console.log('Login success:', response);
                navigate('/home');
            }
        } catch (err) {
            console.log('Login error:', err);
            setErrorMessage('Email sau parola gresita.');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSignIn();
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'rgba(5, 63, 71, 0.4)',
                    padding: isMobile ? 2 : 4,
                    borderRadius: 2,
                    margin: 5
                }}
            >
                <Typography variant={isMobile ? "h4" : "h3"} component="h1" gutterBottom sx={{ color: 'white', fontWeight: 'bold', letterSpacing: 1.5, textAlign: 'center' }}>
                    TTRACKER
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="E-mail"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Parola"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        style: { color: 'white' },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={togglePasswordVisibility}
                                    edge="end"
                                    sx={{ color: 'white' }}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rememberMe}
                            onChange={e => setRememberMe(e.target.checked)}
                            sx={{ color: 'white' }}
                        />
                    }
                    label={<Typography sx={{ color: 'white' }}>Tine-ma minte</Typography>}
                />
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSignIn}
                        sx={{ width: '48%' }}
                    >
                        Conectare
                    </Button>
                    <Typography
                        variant="body2"
                        onClick={handleForgotPassword}
                        sx={{ color: 'gainsboro', cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Ati uitat parola?
                    </Typography>
                </Box>
                <hr style={{ width: '100%', borderTop: '1px solid white', marginBottom: 2 }} />
                <Typography variant="h6" sx={{ color: 'white', marginBottom: 2 }}>
                    Nu aveti inca cont?
                </Typography>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleRegisterClick}
                    sx={{ width: '100%' }}
                >
                    Creati Cont Gratuit
                </Button>
            </Container>
        </Box>
    );
};

export default LoginPage;
