import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Pages/AuthPages/LoginPage/Login';
import HomePage from './Pages/OtherPages/MainPage/HomePage';
import FrontPage from "./Pages/AuthPages/FrontPage/FrontPage";
import RegisterPage from "./Pages/AuthPages/RegisterPage/Register"
import { AuthProvider } from "./API/AuthContext";
import ProtectedRoute from '../src/API/ProtectedRoute';
import CompaniesPage from "./Pages/ListPages/CompaniesPage/CompaniesPage";
import ContactsPage from "./Pages/ListPages/ContactsPage/ContactsPage";
import CompanyPage from "./Pages/EntityPages/CompanyPage/CompanyPage";
import ContactPage from "./Pages/EntityPages/ContactPage/ContactPage";
import TicketsPage from "./Pages/ListPages/TicketsPage/TicketsPage";
import SettingsPage from "./Pages/OtherPages/SettingsPage/SettingsPage";
import ProductsPage from "./Pages/ListPages/ProductsPage/ProductsPage";
import AccountPage from "./Pages/OtherPages/AccountPage/AccountPage";
import UsersPage from "./Pages/ListPages/UsersPage/UsersPage";
import OpportunitiesPage from "./Pages/ListPages/OpportunitiesPage/OpportunitiesPage";
import ActivitiesPage from "./Pages/ListPages/ActivitiesPage/ActivitiesPage";
import OpportunityPage from "./Pages/EntityPages/OpportunityPage/OpportunityPage";
import TicketPage from "./Pages/EntityPages/TicketPage/TicketPage";
import ActivityPage from "./Pages/EntityPages/ActivityPage/ActivityPage";
import './global.css';
import PasswordResetPage from "./Pages/OtherPages/PasswordResetPage/PasswordResetPage";


function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<FrontPage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/contacts" element={<ProtectedRoute element={<ContactsPage/>}/>}/>
                    <Route path="/companies" element={<ProtectedRoute element={<CompaniesPage/>}/>}/>
                    <Route path="/settings" element={<ProtectedRoute element={<SettingsPage/>}/>}/>
                    <Route path="/home" element={<ProtectedRoute element={<HomePage/>} redirectTo="/login"/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/companies/:companyId" element={<ProtectedRoute element={<CompanyPage/>}/>}/>
                    <Route path="/contacts/:contactId" element={<ProtectedRoute element={<ContactPage/>}/>}/>
                    <Route path="/opportunities/:opportunityId" element={<ProtectedRoute element={<OpportunityPage/>}/>}/>
                    <Route path="/tickets/:ticketId" element={<ProtectedRoute element={<TicketPage/>}/>}/>
                    <Route path="/activities/:activityId" element={<ProtectedRoute element={<ActivityPage/>}/>}/>
                    <Route path="/tickets" element={<ProtectedRoute element={<TicketsPage/>}/>}/>
                    <Route path="/products" element={<ProtectedRoute element={<ProductsPage/>}/>}/>
                    <Route path="/account/:accountId" element={<ProtectedRoute element={<AccountPage/>}/>}/>
                    <Route path="/users" element={<ProtectedRoute element={<UsersPage/>}/>}/>
                    <Route path="/opportunities" element={<ProtectedRoute element={<OpportunitiesPage/>}/>}/>
                    <Route path="/activities" element={<ProtectedRoute element={<ActivitiesPage/>}/>}/>
                    <Route path="/password" element={<PasswordResetPage/>}/>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
