import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/tickets';

export const fetchTickets = async (accountId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching tickets:', error);
        throw error;
    }
};

export const addTicket = async (ticketData) => {
    try {
        const response = await axios.post(BASE_URL, ticketData);
        return response.data;
    } catch (error) {
        console.error('Error adding ticket:', error);
        throw error;
    }
};

export const updateTicket = async (id, ticketData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, ticketData);
        return response.data;
    } catch (error) {
        console.error('Error updating ticket:', error);
        throw error;
    }
};

export const deleteTicket = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting ticket:', error);
        throw error;
    }
};

export const fetchTicketsForCompany = async (companyId) => {
    try {
        const response = await axios.get(`${BASE_URL}/companies`, {
                params: { companyId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching tickets:', error);
        throw error;
    }
};

export const fetchTicketsForContact = async (contactId) => {
    try {
        const response = await axios.get(`${BASE_URL}/contacts`, {
            params: { contactId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching tickets:', error);
        throw error;
    }
};

export const fetchTicketDetails = async (ticketId) => {
    const response = await axios.get(`${BASE_URL}/${ticketId}`);
    return response.data;
};
