import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/activities';

export const fetchActivities = async (accountId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching activities:', error);
        throw error;
    }
};

export const addActivity = async (activityData) => {
    try {
        const response = await axios.post(BASE_URL, activityData);
        return response.data;
    } catch (error) {
        console.error('Error adding activity:', error);
        throw error;
    }
};

export const updateActivity = async (id, activityData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, activityData);
        return response.data;
    } catch (error) {
        console.error('Error updating activity:', error);
        throw error;
    }
};

export const deleteActivity = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting activity:', error);
        throw error;
    }
};

export const fetchActivitiesForCompany = async (companyId) => {
    try {
        const response = await axios.get(`${BASE_URL}/companies`, {
            params: { companyId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching activities: ', error);
        throw error;
    }
};

export const fetchActivitiesForContact = async (contactId) => {
    try {
        const response = await axios.get(`${BASE_URL}/contacts`, {
            params: { contactId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching activities: ', error);
        throw error;
    }
};

export const fetchActivitiesForTicket = async (ticketId) => {
    try {
        const response = await axios.get(`${BASE_URL}/tickets`, {
            params: { ticketId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching activities: ', error);
        throw error;
    }
};

export const fetchActivitiesForOpportunity = async (opportunityId) => {
    try {
        const response = await axios.get(`${BASE_URL}/opportunities`, {
            params: { opportunityId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching activities: ', error);
        throw error;
    }
};

export const fetchActivitiesForActivity = async (activityId) => {
    try {
        const response = await axios.get(`${BASE_URL}/activities`, {
            params: { activityId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching activities: ', error);
        throw error;
    }
};

export const fetchActivityDetails = async (activityId) => {
    const response = await axios.get(`${BASE_URL}/${activityId}`);
    return response.data;
};