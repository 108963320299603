import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Autocomplete,
    Stack,
    Select,
    MenuItem,
    Box,
    Typography,
    List,
    ListItem,
    Paper,
    IconButton,
    Popper,
    useMediaQuery
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { fetchUsers } from '../../API/UserAPI';
import { fetchCompanies } from '../../API/CompanyAPI';
import { fetchContacts } from '../../API/ContactAPI';
import { fetchProducts } from '../../API/ProductAPI';
import { useAuth } from "../../API/AuthContext";
import dayjs from 'dayjs';
import { Delete as DeleteIcon } from '@mui/icons-material';

const EditOpportunityFormDialog = ({ open, onClose, onSave, opportunity }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [probability, setProbability] = useState('');
    const [startDate, setStartDate] = useState(dayjs());
    const [finishDate, setFinishDate] = useState(dayjs());
    const [assignedPersons, setAssignedPersons] = useState([]);
    const [contact, setContact] = useState(null);
    const [company, setCompany] = useState(null);
    const [status, setStatus] = useState('');
    const [value, setValue] = useState('');
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [entities, setEntities] = useState([]);
    const [persons, setPersons] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const isDesktop = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const personsData = await fetchUsers(user.accountId);
                setPersons(personsData);

                const companiesData = await fetchCompanies(user.accountId);
                setCompanies(companiesData);

                const contactsData = await fetchContacts(user.accountId);
                setContacts(contactsData);

                const productsData = await fetchProducts(user.accountId);
                setProducts(productsData);

                const combinedData = [
                    ...companiesData.map((company) => ({...company, type: 'Companii'})),
                    ...contactsData.map((contact) => ({...contact, type: 'Contacte'}))
                ];
                setEntities(combinedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user]);

    useEffect(() => {
        if (opportunity) {
            setName(opportunity.name);
            setDescription(opportunity.description);
            setProbability(opportunity.probability);
            setStartDate(dayjs(opportunity.startDate));
            setFinishDate(dayjs(opportunity.finishDate));
            setStatus(opportunity.status);
            setValue(opportunity.value);
            setAssignedPersons(persons.filter(person => opportunity.userIds.includes(person.id)));
            setSelectedProducts(products.filter(product => opportunity.productIds.includes(product.id)));
            setQuantities(opportunity.quantities);
            if (opportunity.associatedEntityType === 'Contact') {
                setContact(contacts.find(contact => contact.id === opportunity.associatedEntityId));
                setCompany(null);
            } else if (opportunity.associatedEntityType === 'Company') {
                setCompany(companies.find(company => company.id === opportunity.associatedEntityId));
                setContact(null);
            }
        }
    }, [opportunity, persons, contacts, companies, products]);

    const handleSave = () => {
        if (startDate.isAfter(finishDate)) {
            setErrorMessage('Data start trebuie să fie înainte de data final.');
            return;
        }

        if (selectedProducts.length > 0 && selectedProducts.some(product => product === null)) {
            setErrorMessage('Vă rugăm să selectați un produs pentru fiecare intrare.');
            return;
        }

        const opportunityData = {
            id: opportunity.id,
            name,
            description,
            probability,
            startDate: startDate.format('YYYY-MM-DD HH:mm'),
            finishDate: finishDate.format('YYYY-MM-DD HH:mm'),
            userIds: assignedPersons.map(person => person.id),
            associatedEntityId: contact ? contact.id : company ? company.id : null,
            associatedEntityType: contact ? 'Contact' : company ? 'Company' : null,
            status,
            value,
            productIds: selectedProducts.map(product => product.id),
            quantities,
            accountId: user.accountId
        };
        onSave(opportunityData);
        handleClose();
    };

    const handleClose = () => {
        onClose();
    };

    const handleProductChange = (index, product) => {
        const newSelectedProducts = [...selectedProducts];
        newSelectedProducts[index] = product;
        setSelectedProducts(newSelectedProducts);
        updateValue(newSelectedProducts, quantities);
    };

    const handleQuantityChange = (index, quantity) => {
        const newQuantities = [...quantities];
        newQuantities[index] = quantity;
        setQuantities(newQuantities);
        updateValue(selectedProducts, newQuantities);
    };

    const updateValue = (products, quantities) => {
        const newValue = products.reduce((total, product, index) => {
            const quantity = quantities[index];
            const priceInRON = product.currency === 'EUR' ? product.price * 4.9 : product.price;
            return total + priceInRON * quantity;
        }, 0);
        setValue(newValue);
    };

    const handleRemoveProduct = (index) => {
        const newSelectedProducts = [...selectedProducts];
        const newQuantities = [...quantities];
        newSelectedProducts.splice(index, 1);
        newQuantities.splice(index, 1);
        setSelectedProducts(newSelectedProducts);
        setQuantities(newQuantities);
        updateValue(newSelectedProducts, newQuantities);
    };

    const CustomPaper = (props) => (
        <Paper {...props} style={{maxHeight: 200, overflow: 'auto'}}/>
    );

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Editeaza oportunitatea</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField label="Nume" value={name} onChange={(e) => setName(e.target.value)} fullWidth/>
                    <TextField label="Descriere" value={description} onChange={(e) => setDescription(e.target.value)}
                               fullWidth multiline minRows={3}/>
                    <TextField label="Probabilitate (%)" value={probability}
                               onChange={(e) => setProbability(e.target.value)} fullWidth/>
                    <DateTimePicker
                        label="Data start"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth/>}
                        sx={{'& .MuiInputBase-root': {height: '40px'}}}
                    />
                    <DateTimePicker
                        label="Data final"
                        value={finishDate}
                        onChange={(newValue) => setFinishDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth/>}
                        sx={{'& .MuiInputBase-root': {height: '40px'}}}
                    />
                    <Autocomplete
                        multiple
                        options={persons}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Persoane responsabile"/>}
                        value={assignedPersons}
                        onChange={(event, newValue) => setAssignedPersons(newValue)}
                        fullWidth
                    />
                    <Autocomplete
                        options={entities}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, {inputValue}) => {
                            if (inputValue === '') {
                                return [];
                            }
                            return options.filter((option) =>
                                option.name.toLowerCase().includes(inputValue.toLowerCase())
                            );
                        }}
                        renderInput={(params) => <TextField {...params} label="Contact/Companie" variant="outlined"/>}
                        renderOption={(props, option) => (
                            <ListItem {...props}>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <Typography variant="body1">
                                        {option.name}
                                    </Typography>
                                </Box>
                            </ListItem>
                        )}
                        value={contact || company}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                if (newValue.type === 'Contacte') {
                                    setContact(newValue);
                                    setCompany(null);
                                } else if (newValue.type === 'Companii') {
                                    setCompany(newValue);
                                    setContact(null);
                                }
                            } else {
                                setContact(null);
                                setCompany(null);
                            }
                        }}
                        PaperComponent={CustomPaper}
                        ListboxComponent={(listboxProps) => (
                            <List {...listboxProps} style={{maxHeight: 200, overflow: 'auto'}}/>
                        )}
                        PopperComponent={(props) => {
                            const {children, ...rest} = props;
                            if (React.Children.count(children) === 0) {
                                return null;
                            }
                            return <Popper {...rest}>{children}</Popper>;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        noOptionsText={null}
                    />
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>Status</MenuItem>
                        <MenuItem value="Castigata">Castigata</MenuItem>
                        <MenuItem value="In asteptare">In asteptare</MenuItem>
                        <MenuItem value="Pierduta">Pierduta</MenuItem>
                    </Select>
                    <TextField
                        label="Valoare (RON)"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        fullWidth
                    />
                    {selectedProducts.map((product, index) => (
                        <Stack key={index} direction="row" spacing={2} alignItems="center">
                            <Autocomplete
                                options={products}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label={`Produs ${index + 1}`}/>}
                                value={product}
                                onChange={(event, newValue) => handleProductChange(index, newValue)}
                                fullWidth
                            />
                            <TextField
                                label="Cantitate"
                                type="number"
                                value={quantities[index]}
                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                fullWidth
                                disabled={!product}
                            />
                            {isDesktop && (
                                <TextField
                                    label="Preț"
                                    value={product ? (product.price * (product.currency === 'EUR' ? 4.9 : 1)).toFixed(2) : ''}
                                    disabled
                                    fullWidth
                                />
                            )}
                            {isDesktop && (
                                <TextField
                                    label="Monedă"
                                    value={product ? product.currency : ''}
                                    disabled
                                    fullWidth
                                />
                            )}
                            <IconButton onClick={() => handleRemoveProduct(index)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Stack>
                    ))}
                    <Button onClick={() => {
                        setSelectedProducts([...selectedProducts, null]);
                        setQuantities([...quantities, 1]);
                    }}>
                        Adauga produs
                    </Button>
                    {errorMessage && (
                        <Typography color="error">{errorMessage}</Typography>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Anuleaza</Button>
                <Button onClick={handleSave} color="primary">Salveaza</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditOpportunityFormDialog;
