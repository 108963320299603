import React, { useState, useEffect } from 'react';
import {
    Box, Button, Stack, Typography, TextField, IconButton, useMediaQuery, MenuItem
} from '@mui/material';
import {
    Edit as EditIcon, Delete as DeleteIcon, Note as NoteIcon, Call as CallIcon, Event as EventIcon,
    Work as WorkIcon, Email as EmailIcon, CloudUpload as CloudUploadIcon
} from '@mui/icons-material';
import { useAuth } from '../../API/AuthContext';
import {
    createNote,
    updateNote,
    deleteNote,
    uploadFiles,
    downloadNoteFile,
    deleteFileFromNote,
    fetchNotesForEntity
} from '../../API/NoteAPI';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';

const NotesComponent = ({ entityId, entityType }) => {
    const { user } = useAuth();
    const [notes, setNotes] = useState([]);
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [files, setFiles] = useState([]);
    const [editingNote, setEditingNote] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [noteToDelete, setNoteToDelete] = useState(null);
    const [existingFiles, setExistingFiles] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    const isMobile = useMediaQuery('(max-width:600px)');

    const loadNotes = async () => {
        try {
            let notesData = [];
            if (entityType)
                notesData = await fetchNotesForEntity(entityId, entityType);
            setNotes(notesData);
        } catch (error) {
            console.error('Failed to load notes:', error);
        }
    };

    useEffect(() => {
        if (entityId) {
            loadNotes();
        }
    }, [entityId]);

    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const handleDeleteFile = (filename) => {
        setFiles(files.filter(file => file.name !== filename));
    };

    const handleMarkFileForDeletion = (filename) => {
        setFilesToDelete([...filesToDelete, filename]);
        setExistingFiles(existingFiles.filter(file => file !== filename));
    };

    const handleAddOrUpdateNote = async () => {
        const duration = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        const noteData = {
            description,
            duration,
            type,
            createdById: user.userId,
            associatedEntityType: entityType,
            associatedEntityId: entityId,
            dateCreated: new Date().toISOString(),
        };

        try {
            let noteResponse;
            if (editingNote) {
                noteResponse = await updateNote(editingNote.id, noteData);
                await Promise.all(filesToDelete.map(file => deleteFileFromNote(file)));
                if (files.length > 0) {
                    const formData = new FormData();
                    files.forEach(file => formData.append('files', file));
                    await uploadFiles(noteResponse.id, formData);
                }
                setEditingNote(null);
            } else {
                noteResponse = await createNote(noteData);
                if (files.length > 0) {
                    const formData = new FormData();
                    files.forEach(file => formData.append('files', file));
                    await uploadFiles(noteResponse.id, formData);
                }
            }

            resetForm();
            loadNotes();
        } catch (error) {
            console.error('Failed to save note:', error);
        }
    };

    const resetForm = () => {
        setDescription('');
        setType('');
        setHours('');
        setMinutes('');
        setFiles([]);
        setExistingFiles([]);
        setFilesToDelete([]);
    };

    const handleEditNote = (note) => {
        setEditingNote(note);
        setDescription(note.description);
        setType(note.type);
        const [hh, mm] = note.duration.split(':');
        setHours(hh);
        setMinutes(mm);
        setFiles([]);
        setExistingFiles(note.files || []);
        setFilesToDelete([]);
    };

    const handleDeleteNote = async () => {
        try {
            await deleteNote(noteToDelete.id);
            setDeleteDialogOpen(false);
            loadNotes();
        } catch (error) {
            console.error('Failed to delete note:', error);
        }
    };

    const handleDownloadFile = async (noteId, filename) => {
        try {
            await downloadNoteFile(noteId, filename);
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };

    const logNoteFiles = (files) => {
        console.log(files);
    };

    const noteTypeIcons = {
        'Apel': <CallIcon />,
        'Intalnire': <EventIcon />,
        'Activitate': <WorkIcon />,
        'Email': <EmailIcon />,
        'Altele': <NoteIcon />
    };

    const getDisplayFileName = (filename) => {
        const underscoreIndex = filename.indexOf('_');
        return underscoreIndex !== -1 ? filename.substring(underscoreIndex + 1) : filename;
    };

    return (
        <Box>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6">Adauga Nota</Typography>
                <TextField
                    label="Descriere"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    inputProps={{ maxLength: 500 }}
                    sx={{ mb: 2 }}
                />
                {isMobile ? (
                    <TextField
                        select
                        label="Tip"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        fullWidth
                        sx={{ mb: 4 }}
                    >
                        {Object.keys(noteTypeIcons).map((noteType) => (
                            <MenuItem key={noteType} value={noteType}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {noteTypeIcons[noteType]}
                                    <Typography variant="body2">{noteType}</Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <Stack direction="row" spacing={1} sx={{ mb: 4 }}>
                        {Object.keys(noteTypeIcons).map((noteType) => (
                            <Box key={noteType} textAlign="center">
                                <IconButton color={type === noteType ? 'primary' : 'default'} onClick={() => setType(noteType)}>
                                    {noteTypeIcons[noteType]}
                                </IconButton>
                                <Typography variant="body2">{noteType}</Typography>
                            </Box>
                        ))}
                    </Stack>
                )}
                <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
                    <TextField
                        label="Ore"
                        type="number"
                        value={hours}
                        onChange={(e) => setHours(e.target.value)}
                        sx={{ maxWidth: 100 }}
                    />
                    <TextField
                        label="Minute"
                        type="number"
                        value={minutes}
                        onChange={(e) => setMinutes(e.target.value)}
                        sx={{ maxWidth: 100 }}
                    />
                </Stack>
                <Stack
                    direction={isMobile ? "column" : "row"}
                    spacing={2}
                    sx={{ mb: 2 }}
                    alignItems={isMobile ? "stretch" : "center"}
                >
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mb: isMobile ? 2 : 0 }}
                        startIcon={<CloudUploadIcon />}
                    >
                        Selecteaza Fisiere
                        <input
                            type="file"
                            hidden
                            multiple
                            onChange={handleFileChange}
                        />
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleAddOrUpdateNote}
                        sx={{ mb: isMobile ? 2 : 0 }}
                    >
                        {editingNote ? 'Editeaza' : 'Adauga'} Nota
                    </Button>
                    {editingNote && (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setEditingNote(null);
                                resetForm();
                            }}
                            sx={{ mb: isMobile ? 2 : 0 }}
                        >
                            Anuleaza
                        </Button>
                    )}
                </Stack>
                {files.length > 0 && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Fisiere atasate:</Typography>
                        {files.map(file => (
                            <Stack direction="row" alignItems="center" key={file.name} spacing={1}>
                                <Typography>{file.name}</Typography>
                                <IconButton onClick={() => handleDeleteFile(file.name)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}
                    </Box>
                )}
                {editingNote && existingFiles.length > 0 && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Fisiere existente:</Typography>
                        {existingFiles.map(file => (
                            <Stack direction="row" alignItems="center" key={file} spacing={1}>
                                <Typography>{getDisplayFileName(file)}</Typography>
                                <IconButton onClick={() => handleMarkFileForDeletion(file)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}
                    </Box>
                )}
            </Box>
            <Box>
                <Typography variant="h6">Note</Typography>
                {notes.map(note => (
                    <Box key={note.id} sx={{ mb: 2, p: 2, border: '1px solid grey'}}>
                        <Typography variant="subtitle2">
                            {note.createdByName} a notat la data de {new Date(note.dateCreated).toLocaleString()}
                            {noteTypeIcons[note.type]}
                        </Typography>
                        <Typography sx={{ wordBreak: 'break-word' }}>{note.description}</Typography>
                        {note.files && note.files.length > 0 && (
                            <>
                                <Box sx = {{ border: '0.7px solid grey', mb:2, mt:2}}></Box>
                                {note.files.map(file => (
                                    <Typography
                                        key={file}
                                        component="a"
                                        onClick={() => handleDownloadFile(note.id, file)}
                                        sx={{ textDecoration: 'underline', cursor: 'pointer', display: 'block' }}
                                    >
                                        {getDisplayFileName(file)}
                                    </Typography>
                                ))}
                            </>
                        )}
                        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                            <IconButton onClick={() => handleEditNote(note)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                                setNoteToDelete(note);
                                setDeleteDialogOpen(true);
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    </Box>
                ))}
            </Box>
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={handleDeleteNote}
                message="Sunteti sigur ca doriti sa stergeti aceasta nota?"
            />
        </Box>
    );
};

export default NotesComponent;
