import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography, Avatar, useMediaQuery, IconButton, TextField, Box, Link as MuiLink } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, DeleteForever as DeleteForeverIcon, Phone as PhoneIcon, ChatBubble as ChatBubbleIcon, CheckBox as CheckBoxIcon, Folder as FolderIcon } from '@mui/icons-material';
import { Paid as PaidIcon, EventNote as EventNoteIcon, StickyNote2 as StickyNoteIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Header from "../../../Components/Header/Header";
import Navbar from "../../../Components/Navbar/Navbar";
import AddActivityFormDialog from '../../../Components/Activities/AddActivityFormDialog';
import EditActivityFormDialog from '../../../Components/Activities/EditActivityFormDialog';
import notePng from './note.png';
import { fetchActivities, addActivity, updateActivity, deleteActivity } from "../../../API/ActivityAPI";
import { fetchContacts } from "../../../API/ContactAPI";
import { fetchCompanies } from "../../../API/CompanyAPI";
import { fetchUsers } from "../../../API/UserAPI";
import { useAuth } from '../../../API/AuthContext';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';

const ActivitiesPage = () => {
    const [activities, setActivities] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activityToDelete, setActivityToDelete] = useState(null);
    const [selectedActivityIds, setSelectedActivityIds] = useState([]);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { user } = useAuth();

    const loadActivities = async () => {
        try {
            const activitiesData = await fetchActivities(user.accountId);
            const contactsData = await fetchContacts(user.accountId);
            const companiesData = await fetchCompanies(user.accountId);
            const usersData = await fetchUsers(user.accountId);

            setContacts(contactsData);
            setCompanies(companiesData);
            setUsers(usersData);

            const activityMap = new Map();
            activitiesData.forEach(activity => {
                if (activity.subactivityEntityType === 'Activity') {
                    const parentActivity = activitiesData.find(parent => parent.id === activity.subactivityEntityId);
                    if (parentActivity) {
                        parentActivity.subactivities = parentActivity.subactivities || [];
                        parentActivity.subactivities.push(activity);
                    }
                } else {
                    activityMap.set(activity.id, activity);
                }
            });

            const enhancedActivities = Array.from(activityMap.values()).map(activity => {
                let contactName = null;
                let contactId = null;
                let companyName = null;
                let companyId = null;

                if (activity.associatedEntityType === 'Contact') {
                    const contact = contactsData.find(c => c.id === activity.associatedEntityId);
                    if (contact) {
                        contactName = contact.name;
                        contactId = contact.id;
                        const company = companiesData.find(c => c.id === contact.companyId);
                        if (company) {
                            companyName = company.name;
                            companyId = company.id;
                        }
                    }
                } else if (activity.associatedEntityType === 'Company') {
                    const company = companiesData.find(c => c.id === activity.associatedEntityId);
                    if (company) {
                        companyName = company.name;
                        companyId = company.id;
                        const contact = contactsData.find(c => c.companyId === company.id);
                        if (contact) {
                            contactName = contact.name;
                            contactId = contact.id;
                        }
                    }
                }

                let assignedPersonNames = [];
                if (activity.userIds && activity.userIds.length > 0) {
                    assignedPersonNames = usersData.filter(user => activity.userIds.includes(user.id)).map(user => user.name);
                }

                return {
                    ...activity,
                    contactName,
                    contactId,
                    companyName,
                    companyId,
                    assignedPersonNames
                };
            });

            setActivities(enhancedActivities);
            setFilteredActivities(enhancedActivities);
        } catch (error) {
            console.error('Failed to load activities:', error);
        }
    };

    useEffect(() => {
        if (user) {
            loadActivities();
        }
    }, [user]);

    const handleSearch = () => {
        const filtered = activities.filter(activity =>
            activity.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredActivities(filtered);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (activity) => {
        setSelectedActivity(activity);
        setEditDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteActivity(id).then(loadActivities).catch(console.error);
    };

    const handleDeleteClick = (activity) => {
        setActivityToDelete(activity);
        setDeleteDialogOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteDialogOpen(true);
    };

    const handleBulkDeleteConfirm = () => {
        Promise.all(selectedActivityIds.map(id => deleteActivity(id)))
            .then(loadActivities)
            .catch(console.error)
            .finally(() => {
                setBulkDeleteDialogOpen(false);
                setSelectedActivityIds([]);
            });
    };

    const handleAddActivity = (activityData) => {
        addActivity(activityData).then(loadActivities).catch(console.error);
        setAddDialogOpen(false);
    };

    const handleEditActivity = (activityData) => {
        updateActivity(activityData.id, activityData).then(loadActivities).catch(console.error);
        setEditDialogOpen(false);
    };

    const handleCellClick = (params, event) => {
        if (params.field !== '__check__') {
            event.stopPropagation();
        }
    };

    const getActivityTypeIcon = (type) => {
        switch (type) {
            case 'Apel':
                return <PhoneIcon />;
            case 'Intalnire':
                return <ChatBubbleIcon />;
            case 'Activitate':
                return <CheckBoxIcon />;
            case 'Altele':
                return <FolderIcon />;
            default:
                return null;
        }
    };

    const getLinkedEntityIcon = (type) => {
        switch (type) {
            case 'Ticket':
                return <StickyNoteIcon color="primary"/>;
            case 'Opportunity':
                return <PaidIcon color="primary"/>;
            case 'Activity':
                return <EventNoteIcon color="primary"/>;
            default:
                return null;
        }
    };

    const getRedirectUrl = (type) => {
        switch (type) {
            case 'Ticket':
                return 'tickets';
            case 'Opportunity':
                return 'opportunities';
            case 'Activity':
                return 'activities';
            default:
                return null;
        }
    };

    const columns = [
        {
            field: 'id',
            headerName: <strong>#</strong>,
            flex: 1,
            maxWidth: 60,
            sortable: true
        },
        {
            field: 'type',
            headerName: <strong>Tip</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => getActivityTypeIcon(params.value)
        },
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <Box>
                    <MuiLink component={Link} to={`/activities/${params.row.id}`} style={{textDecoration: 'underline', color: 'blue'}}>
                        {params.value}
                    </MuiLink>
                    {params.row.subactivityEntityId && (
                        <Box display="flex" alignItems="center">
                            {'Legata de:'}
                            {getLinkedEntityIcon(params.row.subactivityEntityType)}
                            {' '}
                            <MuiLink component={Link} to={`/${getRedirectUrl(params.row.subactivityEntityType)}/${params.row.subactivityEntityId}`} style={{textDecoration: 'underline', color: 'blue'}}>
                                {'#' + params.row.subactivityEntityId}
                            </MuiLink>
                        </Box>
                    )}
                    {params.row.subactivities && params.row.subactivities.length > 0 && params.row.subactivities.map(subactivity => (
                        <Box key={subactivity.id} display="flex" alignItems="center">
                            {'Legata de:'}
                            {getLinkedEntityIcon(subactivity.subactivityEntityType)}
                            {' '}
                            <MuiLink component={Link} to={`/${getRedirectUrl(subactivity.subactivityEntityType)}/${subactivity.id}`} style={{textDecoration: 'underline', color: 'blue'}}>
                                {'#' + subactivity.id}
                            </MuiLink>
                        </Box>
                    ))}
                </Box>
            ),
        },
        {
            field: 'contactName',
            headerName: <strong>Contact</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <MuiLink component={Link} to={`/contacts/${params.row.contactId}`} style={{textDecoration: 'underline', color: 'blue'}}>
                    {params.value}
                </MuiLink>
            ),
        },
        {
            field: 'companyName',
            headerName: <strong>Companie</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <MuiLink component={Link} to={`/companies/${params.row.companyId}`} style={{textDecoration: 'underline', color: 'blue'}}>
                    {params.value}
                </MuiLink>
            ),
        },
        {field: 'status', headerName: <strong>Status</strong>, flex: 2, sortable: true},
        {field: 'startDate', headerName: <strong>Data start</strong>, flex: 2, sortable: true,},
        {field: 'finishDate', headerName: <strong>Data final</strong>, flex: 2, sortable: true,},
        {
            field: 'assignedPersonNames',
            headerName: <strong>Responsabili</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: <strong>Actiuni</strong>,
            flex: 2,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const mobileColumns = [
        {
            field: 'name',
            headerName: 'Nume',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Box>
                    <MuiLink component={Link} to={`/activities/${params.row.id}`} style={{textDecoration: 'underline', color: 'blue'}}>
                        {params.value}
                    </MuiLink>
                    {params.row.subactivityEntityId && (
                        <Box display="flex" alignItems="center">
                            {'Legata de:'}
                            {getLinkedEntityIcon(params.row.subactivityEntityType)}
                            {' '}
                            <MuiLink component={Link} to={`/${getRedirectUrl(params.row.subactivityEntityType)}/${params.row.subactivityEntityId}`} style={{textDecoration: 'underline', color: 'blue'}}>
                                {'#' + params.row.subactivityEntityId}
                            </MuiLink>
                        </Box>
                    )}
                    {params.row.subactivities && params.row.subactivities.length > 0 && params.row.subactivities.map(subactivity => (
                        <Box key={subactivity.id} display="flex" alignItems="center">
                            {'Legata de:'}
                            {getLinkedEntityIcon(subactivity.subactivityEntityType)}
                            {' '}
                            <MuiLink component={Link} to={`/${getRedirectUrl(subactivity.subactivityEntityType)}/${subactivity.id}`} style={{textDecoration: 'underline', color: 'blue'}}>
                                {'#' + subactivity.id}
                            </MuiLink>
                        </Box>
                    ))}
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actiuni',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <div style={{display: 'flex'}}>
            <Header
                isNavbarVisible={isNavbarVisible}
                setIsNavbarVisible={setIsNavbarVisible}
                onRefreshActivities={loadActivities}
            />

            <Navbar isNavbarVisible={isNavbarVisible}/>
            <div style={{
                marginLeft: isNavbarVisible ? isMobile? '80px' : `calc(10vw)` : isMobile? '0px' : '0px',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{marginLeft: isMobile ? '25px' : '30px', height: '100%', width: 'calc(100% - 40px)'}}>
                    <div style={{padding: '10px', margin: '10px'}}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Avatar alt="s" src={notePng}/>
                            <Typography variant="h5">Activitati</Typography>
                            {isDesktop && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)}>Adauga</Button>
                            )}
                            {isDesktop && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        id="outlined-basic"
                                        label="Cauta"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleSearch}>
                                        <SearchIcon/>
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                        {isMobile && (
                            <Button variant="contained" onClick={() => setAddDialogOpen(true)} style={{ marginTop: '10px' }}>
                                Adauga
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            endIcon={<DeleteForeverIcon/>}
                            onClick={handleBulkDeleteClick}
                            size="small"
                            style={{marginBottom: '10px', padding: '4px 8px', fontSize: '10px'}}
                        >
                            Sterge selectia
                        </Button>
                        <DataGrid
                            rows={filteredActivities}
                            columns={isMobile ? mobileColumns : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 15},
                                },
                                sorting: {
                                    sortModel: [{field: 'id', sort: 'desc'}],
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowSelectionModelChange={(newSelection) => {
                                setSelectedActivityIds(newSelection);
                            }}
                            onCellClick={handleCellClick}
                            autoHeight
                            disableExtendRowFullWidth
                            getRowHeight={() => 'auto'}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell--editable': {
                                    outline: 'none',
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    overflow: 'visible',
                                    whiteSpace: 'normal',
                                    lineHeight: 'normal',
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid lightgray",
                                    padding: "5px",
                                    backgroundColor: "white",
                                    width: "calc(100% - 2px)",
                                }
                            }}
                            localeText={{
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Pe pagina',
                                },
                                noRowsLabel: "Nici o inregistrare",
                                toolbarDensity: {
                                    density: 'Density',
                                    densityLabel: 'Density',
                                    densityCompact: 'Compact',
                                    densityStandard: 'Standard',
                                    densityComfortable: 'Comfortable',
                                },
                            }}
                            classes={{root: 'custom-data-grid'}}
                        />
                    </div>
                </div>
            </div>
            <AddActivityFormDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSave={handleAddActivity}
            />
            <EditActivityFormDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleEditActivity}
                activity={selectedActivity}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={() => {
                    handleDelete(activityToDelete.id);
                    setDeleteDialogOpen(false);
                }}
                message="Sunteti sigur ca vreti sa stergeti activitatea?"
            />
            <ConfirmationDialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                onConfirm={handleBulkDeleteConfirm}
                message="Sunteti sigur ca vreti sa stergeti activitatile selectate?"
            />
        </div>
    );
};

export default ActivitiesPage;
