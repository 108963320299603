import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, IconButton, Avatar, Stack } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import settingsImage from "./settings-gears.png";
import { useAuth } from '../../../API/AuthContext';

function SettingsPage() {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [marginLeft, setMarginLeft] = useState('10vw');
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 768) {
                setMarginLeft('15vw');
            } else {
                setMarginLeft('10vw');
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? `calc(${marginLeft} + 20px)` : '0',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%',
                padding: '20px'
            }}>
                <Stack spacing={2} direction="row" alignItems="center">
                    <Avatar alt="s" src={settingsImage} />
                    <Typography variant="h4" >Setari</Typography>
                </Stack>
                <Box mt={4} />
                <Grid container spacing={4} justifyContent="left">
                    <Grid item>
                        <Box
                            sx={{
                                width: 150,
                                height: 150,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid gray',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                            }}
                            onClick={() => handleNavigation(`/account/${user.accountId}`)}
                        >
                            <IconButton size="large">
                                <AccountCircleIcon sx={{ fontSize: 84 }} />
                            </IconButton>
                            <Typography variant="h6">Contul meu</Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                width: 150,
                                height: 150,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid gray',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                            }}
                            onClick={() => handleNavigation('/users')}
                        >
                            <IconButton size="large">
                                <GroupIcon sx={{ fontSize: 84 }} />
                            </IconButton>
                            <Typography variant="h6">Utilizatori</Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                width: 150,
                                height: 150,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid gray',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                            }}
                            onClick={() => handleNavigation('/products')}
                        >
                            <IconButton size="large">
                                <ShoppingCartIcon sx={{ fontSize: 84 }} />
                            </IconButton>
                            <Typography variant="h6">Produse</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default SettingsPage;
