import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Alert, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { useAuth } from "../../API/AuthContext";

const EditUserFormDialog = ({ open, onClose, onSave, user: userToUpdate }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('USER');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [photo, setPhoto] = useState(null);
    const [alert, setAlert] = useState({ severity: '', message: '' });
    const { user } = useAuth();
    const isDesktop = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        if (userToUpdate) {
            setName(userToUpdate.name);
            setEmail(userToUpdate.email);
            setRole(userToUpdate.role);
        }
    }, [userToUpdate]);

    const handleSave = async () => {
        if (password && password !== confirmPassword) {
            setAlert({ severity: 'error', message: 'Parolele nu sunt identice' });
            return;
        }
        const userData = { id: userToUpdate.id, name, email, role, password: password || undefined, accountId: user.accountId };
        try {
            await onSave(userData, photo);
            setAlert({ severity: 'success', message: 'Utilizator modificat cu succes' });
        } catch (error) {
            setAlert({ severity: 'error', message: 'Utilizatorul nu a fost modificat' });
        }
    };

    const getRoleDescription = (role) => {
        if (role === 'ADMIN') {
            return (
                <Typography variant="body2" color="textSecondary">
                    Poate crea utilizatori.<br />
                    Poate edita datele utilizatorilor.<br />
                    Poate crea date.<br />
                    Poate edita date.<br />
                    Poate șterge date.
                </Typography>
            );
        } else if (role === 'USER') {
            return (
                <Typography variant="body2" color="textSecondary">
                    Poate crea date.<br />
                    Poate edita date.
                </Typography>
            );
        }
        return null;
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Editează Utilizator</DialogTitle>
            <DialogContent>
                {alert.message && (
                    <Alert severity={alert.severity} onClose={() => setAlert({ severity: '', message: '' })}>
                        {alert.message}
                    </Alert>
                )}
                <Stack spacing={2} sx={{ minWidth: isDesktop ? '500px' : 'auto' }}>
                    <TextField label="Nume" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
                    <TextField label="Parola" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth />
                    <TextField label="Confirmă Parola" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} fullWidth />
                    <TextField select label="Rol" value={role} onChange={(e) => setRole(e.target.value)} fullWidth>
                        <MenuItem value="ADMIN">Administrator</MenuItem>
                        <MenuItem value="USER">Utilizator</MenuItem>
                    </TextField>
                    {getRoleDescription(role)}
                    <input type="file" accept="image/*" onChange={(e) => setPhoto(e.target.files[0])} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Anulează</Button>
                <Button onClick={handleSave}>Salvează</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserFormDialog;
