import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography, Avatar, Box, useMediaQuery, IconButton, Alert } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import { fetchOpportunityDetails, updateOpportunity, deleteOpportunity } from '../../../API/OpportunityAPI';
import { fetchContactDetails } from '../../../API/ContactAPI';
import { useAuth } from '../../../API/AuthContext';
import opportunityImage from '../../ListPages/OpportunitiesPage/opportunity.png';
import NotesComponent from "../../../Components/Notes/NoteComponent";
import EditOpportunityFormDialog from '../../../Components/Opportunities/EditOpportunityFormDialog';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import ActivitiesForEntityTable from "../../../Components/Activities/ActivitiesForEntityTable";
import AddActivityForEntityFormDialog from "../../../Components/Activities/AddActivityForEntityFormDialog";
import { addActivity, fetchActivitiesForOpportunity } from '../../../API/ActivityAPI';

const OpportunityPage = () => {
    const { opportunityId } = useParams();
    const [opportunity, setOpportunity] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [activities, setActivities] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [alert, setAlert] = useState({ message: '', severity: '', visible: false });
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if(opportunityId) {
            loadOpportunityDetails();
            loadActivities();
        }
    }, [opportunityId]);

    const loadOpportunityDetails = async () => {
        try {
            const data = await fetchOpportunityDetails(opportunityId);
            setOpportunity(data);
            if (data.associatedEntityType === "Contact") {
                const contactData = await fetchContactDetails(data.associatedEntityId);
                if (contactData && contactData.companyName) {
                    setCompanyName(contactData.companyName);
                    setCompanyId(contactData.companyId);
                }
            }
        } catch (error) {
            console.error('Failed to load opportunity details:', error);
        }
    };

    const loadActivities = async () => {
        try {
            const activitiesData = await fetchActivitiesForOpportunity(opportunityId);
            setActivities(activitiesData);
        } catch (error) {
            console.error('Failed to load activities:', error);
        }
    };

    const handleEditOpportunity = async (updatedOpportunity) => {
        try {
            const updatedData = await updateOpportunity(opportunityId, updatedOpportunity);
            setOpportunity(updatedData);
            setEditDialogOpen(false);
            showAlert('Oportunitate editată cu succes', 'success');
        } catch (error) {
            console.error('Failed to update opportunity:', error);
        }
    };

    const handleDeleteOpportunity = async () => {
        try {
            await deleteOpportunity(opportunityId);
            navigate('/opportunities');
            showAlert('Oportunitate ștearsă cu succes', 'success');
        } catch (error) {
            console.error('Failed to delete opportunity:', error);
        }
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const handleAddActivityClick = () => {
        setActivityDialogOpen(true);
    };

    const handleAddActivity = async (activityData) => {
        try {
            await addActivity(activityData);
            loadActivities();
            showAlert('Activitate adăugată cu succes', 'success');
        } catch (error) {
            console.error('Failed to add activity:', error);
        } finally {
            setActivityDialogOpen(false);
        }
    };

    const showAlert = (message, severity) => {
        setAlert({ message, severity, visible: true });
        setTimeout(() => {
            setAlert({ message: '', severity: '', visible: false });
        }, 3000);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? `calc(10vw)` : '0',
                marginTop: '40px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{
                    marginLeft: isMobile ? '55px' : '10px',
                    height: '100%',
                    width: `calc(100% - ${isMobile ? '65px' : '30px'})`
                }}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        {alert.visible && (
                            <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, visible: false })}>
                                {alert.message}
                            </Alert>
                        )}
                        <Box sx={{
                            padding: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                            marginBottom: 2,
                            overflow: 'hidden',
                            wordBreak: 'break-word'
                        }}>
                            {opportunity && (
                                <>
                                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                                        <Avatar alt={opportunity.name} src={opportunityImage} sx={{ width: 56, height: 56 }} />
                                        <Box flexGrow={1} sx={{ minWidth: '250px', maxWidth: '100%' }}>
                                            <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>{opportunity.name}</Typography>
                                            {opportunity.associatedEntityType === 'Company' && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Compania: </strong>
                                                    <Link to={`/companies/${opportunity.associatedEntityId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {opportunity.associatedEntityName}
                                                    </Link>
                                                </Typography>
                                            )}
                                            {opportunity.associatedEntityType === 'Contact' && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Contact: </strong>
                                                    <Link to={`/contacts/${opportunity.associatedEntityId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {opportunity.associatedEntityName}
                                                    </Link>
                                                </Typography>
                                            )}

                                            {opportunity.associatedEntityType === 'Contact' && companyName && companyId && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>de la compania </strong>
                                                    <Link to={`/companies/${companyId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {companyName}
                                                    </Link>
                                                </Typography>
                                            )}

                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Valoare</strong>: {opportunity.value}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Sansa</strong>: {opportunity.probability}%</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Status</strong>: {opportunity.status}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data start</strong>: {opportunity.startDate}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data final</strong>: {opportunity.finishDate}</Typography>

                                            {isMobile && (
                                                <>
                                                    <Typography variant="body1"><strong>Responsabili</strong>:</Typography>
                                                    {opportunity.userNames?.map((name, index) => (
                                                        <Typography key={index} variant="body1" sx={{ wordBreak: 'break-word' }}>{name}</Typography>
                                                    ))}
                                                </>
                                            )}
                                            {!isMobile && (
                                                <>
                                                    {opportunity.userNames?.map((name, index) => (
                                                        <Typography key={index} variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Responsabili</strong>: {name}
                                                        </Typography>
                                                    ))}
                                                </>
                                            )}
                                        </Box>
                                        {!isMobile && (
                                            <Stack direction="row" spacing={1}>
                                                <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="secondary" disabled={user.role === 'USER'}
                                                            onClick={handleDeleteClick}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton color="default" onClick={() => handleAddActivityClick()}>
                                                    <CalendarMonthIcon />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                    {isMobile && (
                                        <Stack direction="row" spacing={1} justifyContent="center" sx={{ marginTop: 2 }}>
                                            <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" disabled={user.role === 'USER'}
                                                        onClick={handleDeleteClick}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton color="default" onClick={() => handleAddActivityClick()}>
                                                <CalendarMonthIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Box>
                        {opportunity && (
                            <>
                                <Box sx={{ marginBottom: 2 }}>
                                    <ActivitiesForEntityTable entityId={opportunityId} entityType="Opportunity" activities={activities} />
                                </Box>
                                <Box>
                                    <NotesComponent entityId={opportunityId} entityType="Opportunity" />
                                </Box>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {opportunity && (
                <>
                    <EditOpportunityFormDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        onSave={handleEditOpportunity}
                        opportunity={opportunity}
                    />
                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={handleDeleteOpportunity}
                        message="Sunteti sigur ca vreti sa stergeti oportunitatea?"
                    />
                    <AddActivityForEntityFormDialog
                        open={activityDialogOpen}
                        onClose={() => setActivityDialogOpen(false)}
                        onSave={handleAddActivity}
                        subactivityEntityId={opportunity.id}
                        subactivityEntityType="Opportunity"
                        entity={opportunity}
                    />
                </>
            )}
        </div>
    );
};

export default OpportunityPage;
