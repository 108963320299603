import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button, TextField, Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Avatar, Box, Alert
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import { useAuth } from '../../../API/AuthContext';
import { fetchAccount, updateAccount, deleteAccount, changePassword, requestPassword } from '../../../API/AccountAPI';
import settingsImage from './settings-gear2.png';
import accountImage from './account.png';

function AccountPage() {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [marginLeft, setMarginLeft] = useState('10vw');
    const [accountName, setAccountName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [deleteConfirm, setDeleteConfirm] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [alert, setAlert] = useState({ severity: '', message: '' });

    const { user, logout } = useAuth();
    const { accountId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 768) {
                setMarginLeft('15vw');
            } else {
                setMarginLeft('10vw');
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const loadAccountData = async () => {
            try {
                const accountData = await fetchAccount(accountId, user.userId);
                setAccountName(accountData.name);
                setUserName(accountData.userName);
                setUserEmail(accountData.email);
                setUserRole(accountData.role);
            } catch (error) {
                console.error('Failed to load account data:', error);
            }
        };
        loadAccountData();
    }, [user, accountId, navigate]);

    const handleSave = async () => {
        try {
            await updateAccount(accountId, {
                email: userEmail,
                name: accountName,
                accountId: user.accountId,
                userId: user.userId,
                role: userRole,
                userName: userName
            });
            setAlert({ severity: 'success', message: 'Contul a fost actualizat cu succes. Pentru a vedea schimbarile va rog relogati-va' });
        } catch (error) {
            console.error('Failed to update account:', error);
            setAlert({ severity: 'error', message: 'A apărut o eroare la actualizarea contului.' });
        }
    };

    const handleDelete = async () => {
        if (deleteConfirm !== 'CONFIRM STERGEREA') {
            setAlert({ severity: 'warning', message: 'Scrie "CONFIRM STERGEREA" pentru a confirma stergerea.' });
            return;
        }
        try {
            await deleteAccount(accountId);
            setAlert({ severity: 'success', message: 'Contul a fost șters cu succes' });
            logout();
            navigate('/');
        } catch (error) {
            console.error('Failed to delete account:', error);
            setAlert({ severity: 'error', message: 'A apărut o eroare la ștergerea contului.' });
        }
    };

    const handlePasswordChange = async () => {
        if (newPassword !== confirmNewPassword) {
            setAlert({ severity: 'error', message: 'Parolele nu coincid.' });
            return;
        }
        try {
            await changePassword(user.userId, currentPassword, newPassword);
            setAlert({ severity: 'success', message: 'Parola a fost schimbată cu succes' });
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            console.error('Failed to change password:', error);
            setAlert({ severity: 'error', message: 'A apărut o eroare la schimbarea parolei.' });
        }
    };

    const handleRequestPassword = async () => {
        try {
            await requestPassword(user.userId);
            setAlert({ severity: 'success', message: 'Cererea pentru parolă a fost trimisă pe email-ul contului' });
        } catch (error) {
            console.error('Failed to request password:', error);
            setAlert({ severity: 'error', message: 'A apărut o eroare la trimiterea cererii pentru parolă.' });
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? `calc(${marginLeft} + 20px)` : '0',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{ marginLeft: '10px', padding: '20px' }}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Avatar alt="s" src={settingsImage} onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }} />
                        <Typography variant="h4">/</Typography>
                        <Avatar alt="Account" src={accountImage} />
                        <Typography variant="h4" >Setari</Typography>
                    </Stack>
                    <Box mt={4} />
                    {alert.message && (
                        <Alert severity={alert.severity} onClose={() => setAlert({ severity: '', message: '' })}>
                            {alert.message}
                        </Alert>
                    )}
                    <Stack spacing={3}>
                        <Typography variant="h4">Detaliile contului</Typography>
                        <TextField
                            label="Nume cont"
                            value={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                            fullWidth
                            disabled={userRole !== 'OWNER'}
                        />
                        <TextField
                            label="Nume utilizator"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            fullWidth
                            disabled={userRole !== 'OWNER'}
                        />
                        <TextField
                            label="Email utilizator"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            fullWidth
                            disabled={userRole !== 'OWNER'}
                        />
                        <TextField
                            label="Parola curenta"
                            type={showCurrentPassword ? 'text' : 'password'}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            fullWidth
                            disabled={userRole !== 'OWNER'}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                    >
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                )
                            }}
                        />
                        <TextField
                            label="Parola noua"
                            type={showNewPassword ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            disabled={userRole !== 'OWNER'}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                )
                            }}
                        />
                        <TextField
                            label="Confirmare parola noua"
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            fullWidth
                            disabled={userRole !== 'OWNER'}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                    >
                                        {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                )
                            }}
                        />
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePasswordChange}
                                disabled={userRole !== 'OWNER'}
                            >
                                Schimba parola
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={userRole !== 'OWNER'}
                            >
                                Salvare
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setDeleteDialogOpen(true)}
                                disabled={userRole !== 'OWNER'}
                            >
                                Sterge contul
                            </Button>
                        </Stack>
                    </Stack>
                    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                        <DialogTitle>Confirma stergerea contului</DialogTitle>
                        <DialogContent>
                            <Typography>Completeaza "CONFIRM STERGEREA" pentru a confirma stergerea (actiune ireversibila):</Typography>
                            <TextField
                                value={deleteConfirm}
                                onChange={(e) => setDeleteConfirm(e.target.value)}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                                Anuleaza
                            </Button>
                            <Button onClick={handleDelete} color="secondary">
                                Sterge
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default AccountPage;
