import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, Button, Snackbar, Alert } from '@mui/material';
import { useAuth } from "../../API/AuthContext";
import { addCompany } from '../../API/CompanyAPI';
import { addActivity } from '../../API/ActivityAPI';
import { addContact } from '../../API/ContactAPI';
import { addOpportunity } from '../../API/OpportunityAPI';
import { addTicket } from '../../API/TicketAPI';
import AddCompanyFormDialog from '../Companies/AddCompanyFormDialog';
import AddActivityFormDialog from '../Activities/AddActivityFormDialog';
import AddContactFormDialog from '../Contacts/AddContactFormDialog';
import AddOpportunityFormDialog from '../Opportunities/AddOpportunityFormDialog';
import AddTicketFormDialog from '../Tickets/AddTicketFormDialog';
import './Header.css';

const Header = ({ isNavbarVisible, setIsNavbarVisible, onRefreshCompanies, onRefreshActivities, onRefreshContacts, onRefreshOpportunities, onRefreshTickets }) => {
    const { logout, user } = useAuth();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState({ company: false, activity: false, contact: false, opportunity: false, ticket: false });
    const [alert, setAlert] = useState({ message: '', severity: '', visible: false });

    const handleCreateButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = (dialogType) => {
        setDialogOpen({ ...dialogOpen, [dialogType]: true });
        handleMenuClose();
    };

    const handleDialogClose = () => {
        setDialogOpen({ company: false, activity: false, contact: false, opportunity: false, ticket: false });
    };

    const handleSave = (entity, refreshCallback) => (data) => {
        entity(data)
            .then(() => {
                console.log(`${entity.name} added successfully`);
                refreshCallback();
                showAlert(`Adaugare realizara cu succes`, 'success');
                handleDialogClose();
            })
            .catch(error => {
                console.error(error);
                showAlert('Adaugare esuata', 'error');
            });
    };

    const refreshCurrentPage = () => {
        const path = location.pathname;
        if (path.includes('companies') && onRefreshCompanies) {
            onRefreshCompanies();
        } else if (path.includes('activities') && onRefreshActivities) {
            onRefreshActivities();
        } else if (path.includes('contacts') && onRefreshContacts) {
            onRefreshContacts();
        } else if (path.includes('opportunities') && onRefreshOpportunities) {
            onRefreshOpportunities();
        } else if (path.includes('tickets') && onRefreshTickets) {
            onRefreshTickets();
        }
    };

    const showAlert = (message, severity) => {
        setAlert({ message, severity, visible: true });
        setTimeout(() => {
            setAlert({ message: '', severity: '', visible: false });
        }, 3000);
    };

    return (
        <div>
            <header className="header">
                <button className="navbar-toggle" onClick={() => setIsNavbarVisible(!isNavbarVisible)}>☰</button>
                <Link to="/home" className="logo">TTracker</Link>
                <Button
                    className="create-btn"
                    onClick={handleCreateButtonClick}
                    sx={{
                        backgroundColor: '#3498DB',
                        color: 'white',
                        border: 'none',
                        padding: '3px 12px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        marginLeft: '30px',
                        fontSize: 'calc(.5vw + .5vh + .5vmin)',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#2980B9'
                        }
                    }}
                >
                    CREAZA
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={() => handleDialogOpen('company')}>Companie</MenuItem>
                    <MenuItem onClick={() => handleDialogOpen('activity')}>Activitate</MenuItem>
                    <MenuItem onClick={() => handleDialogOpen('contact')}>Contact</MenuItem>
                    <MenuItem onClick={() => handleDialogOpen('opportunity')}>Oportunitate</MenuItem>
                    <MenuItem onClick={() => handleDialogOpen('ticket')}>Tichet</MenuItem>
                </Menu>
                <div className="user-info">
                    <span className="username">{user?.userName}</span>
                    <Button
                        className="logout-btn"
                        onClick={logout}
                        sx={{
                            backgroundColor: '#3498DB',
                            color: 'white',
                            border: 'none',
                            padding: '3px 12px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            fontSize: 'calc(.5vw + .5vh + .5vmin)',
                            fontWeight: 'bold',
                            marginRight: '13px',
                            '&:hover': {
                                backgroundColor: '#2980B9'
                            }
                        }}
                    >
                        Deconectare
                    </Button>
                </div>
            </header>
            <AddCompanyFormDialog open={dialogOpen.company} onClose={handleDialogClose} onSave={handleSave(addCompany, refreshCurrentPage)} />
            <AddActivityFormDialog open={dialogOpen.activity} onClose={handleDialogClose} onSave={handleSave(addActivity, refreshCurrentPage)} />
            <AddContactFormDialog open={dialogOpen.contact} onClose={handleDialogClose} onSave={handleSave(addContact, refreshCurrentPage)} />
            <AddOpportunityFormDialog open={dialogOpen.opportunity} onClose={handleDialogClose} onSave={handleSave(addOpportunity, refreshCurrentPage)} />
            <AddTicketFormDialog open={dialogOpen.ticket} onClose={handleDialogClose} onSave={handleSave(addTicket, refreshCurrentPage)} />
            <Snackbar open={alert.visible} autoHideDuration={3000} onClose={() => setAlert({ ...alert, visible: false })}>
                <Alert onClose={() => setAlert({ ...alert, visible: false })} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Header;
