import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/accounts';

export const fetchAccount = async (accountId, userId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId, userId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching account:', error);
        throw error;
    }
};

export const updateAccount = async (id, accountData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, accountData);
        return response.data;
    } catch (error) {
        console.error('Error updating account:', error);
        throw error;
    }
};

export const deleteAccount = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting account:', error);
        throw error;
    }
};

export const changePassword = async (userId, currentPassword, newPassword) => {
    try {
        await axios.put(`${BASE_URL}/change-password/${userId}/${currentPassword}/${newPassword}`);
    } catch (error) {
        console.error('Error changing password:', error);
        throw error;
    }
};

export const requestPassword = async (userId) => {
    try {
        await axios.post(`${BASE_URL}/request-password/${userId}`);
    } catch (error) {
        console.error('Error requesting password:', error);
        throw error;
    }
};
