const API_BASE_URL = "https://api.crminternal.mediakom.ro:8181/auth";

const AuthService = {
    signIn: async (email, password) => {
        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password
                }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            return {
                token: data.token,
                userId: data.userId,
                userName: data.userName,
                accountId: data.accountId,
                role: data.role
            };
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    },

    signUp: async (signUpRequest) => {
        try {
            const response = await fetch(`${API_BASE_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(signUpRequest),
            });

            if (!response.ok) {
                throw new Error('Registration failed');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    },
};

export default AuthService;
