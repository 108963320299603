import React, { useState, useEffect } from 'react';
import {Typography, useMediaQuery} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { fetchContactsForCompany } from '../../API/ContactAPI';

const ContactsForCompanyTable = ({ companyId }) => {
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const loadContacts = async () => {
            try {
                const contactsData = await fetchContactsForCompany(companyId);
                setContacts(contactsData);
                setFilteredContacts(contactsData);
            } catch (error) {
                console.error('Failed to load contacts:', error);
            }
        };

        if (companyId) {
            loadContacts();
        }
    }, [companyId]);
    const columns = [
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/contacts/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'email', headerName: <strong>Email</strong>, flex: 1, sortable: true },
        { field: 'phoneNumber', headerName: <strong>Telefon</strong>, flex: 1, sortable: true },
        { field: 'function', headerName: <strong>Functie</strong>, flex: 1, sortable: true },
        {
            field: 'assignedPersonName',
            headerName: <strong>Responsabil</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value}
                </div>
            ),
        }
    ];

    const mobileColumns = [
        {
            field: 'details',
            headerName: 'Details',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    <div><strong>Nume:</strong> {params.row.name}</div>
                    <div><strong>Email:</strong> {params.row.email}</div>
                    <div><strong>Telefon:</strong> {params.row.phoneNumber}</div>
                    <div><strong>Functie:</strong> {params.row.function}</div>
                    <div><strong>Responsabil:</strong> {params.row.assignedPersonName}</div>
                </div>
            )
        }
    ];

    return (
        <div>
            <Typography variant="h5"><strong>Contacte</strong></Typography>
            <DataGrid
                rows={filteredContacts}
                columns={isMobile ? mobileColumns : columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                disableSelectionOnClick
                autoHeight
                disableExtendRowFullWidth
                getRowHeight={() => 'auto'}
                disableColumnMenu
                sx={{
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell--editable': {
                        outline: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid lightgray",
                        padding: "5px",
                        backgroundColor: "white",
                        width: "calc(100% - 2px)",
                    }
                }}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Pe pagina',
                    },
                    noRowsLabel: "Nici o inregistrare",
                    toolbarDensity: {
                        density: 'Density',
                        densityLabel: 'Density',
                        densityCompact: 'Compact',
                        densityStandard: 'Standard',
                        densityComfortable: 'Comfortable',
                    },
                }}
                classes={{ root: 'custom-data-grid' }}
            />
        </div>
    );
};

export default ContactsForCompanyTable;
