import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import { Box, Typography, useMediaQuery, Paper } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchOpportunities } from '../../../API/OpportunityAPI';
import { fetchTickets } from '../../../API/TicketAPI';
import { fetchActivities } from '../../../API/ActivityAPI';
import { useAuth } from '../../../API/AuthContext';

const HomePage = () => {
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [marginLeft, setMarginLeft] = useState('10vw');
    const [opportunitiesData, setOpportunitiesData] = useState([]);
    const [activitiesData, setActivitiesData] = useState([]);
    const [ticketsData, setTicketsData] = useState([]);
    const { user } = useAuth();

    const isMobile = useMediaQuery('(max-width:600px)');

    const fetchData = useCallback(async () => {
        try {
            const [opportunities, activities, tickets] = await Promise.all([
                fetchOpportunities(user.accountId),
                fetchActivities(user.accountId),
                fetchTickets(user.accountId)
            ]);

            setOpportunitiesData(processOpportunities(opportunities));
            setActivitiesData(processActivities(activities));
            setTicketsData(processTickets(tickets));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [user.accountId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const processOpportunities = (opportunities) => {
        const statusCounts = { 'In asteptare': 0, 'Castigata': 0, 'Pierduta': 0 };
        opportunities.forEach(opportunity => {
            statusCounts[opportunity.status]++;
        });
        return Object.entries(statusCounts).map(([name, value]) => ({ name, value })).filter(entry => entry.value > 0);
    };

    const processActivities = (activities) => {
        const statusCounts = { 'Programata': 0, 'In curs': 0, 'Finalizata': 0 };
        activities.forEach(activity => {
            statusCounts[activity.status]++;
        });
        return Object.entries(statusCounts).map(([name, value]) => ({ name, value })).filter(entry => entry.value > 0);
    };

    const processTickets = (tickets) => {
        const statusCounts = { 'In lucru': 0, 'In asteptare': 0, 'Inchis': 0 };
        tickets.forEach(ticket => {
            statusCounts[ticket.status]++;
        });
        return Object.entries(statusCounts).map(([name, value]) => ({ name, value })).filter(entry => entry.value > 0);
    };

    const COLORS = {
        opportunities: ['#FFBB28', '#00C49F', '#0088FE'],
        activities: ['#FFBB28', '#0088FE', '#00C49F'],
        tickets: ['#FFBB28', '#0088FE', '#00C49F'],
    };

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (percent === 0) return null;

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const renderPieChart = (data, title, colorSet) => (
        <Paper sx={{ width: isMobile ? '90%' : '33%', height: isMobile ? 250 : 500, mb: 4, p: 8, backgroundColor: '#f5f5f5', marginLeft: isMobile ? '5%' : '0', marginRight: isMobile ? '5%' : '0' }}>
            <Typography variant="h6" sx={{ color: 'black', textAlign: 'center', mb: 2 }}>{title}</Typography>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={isMobile ? 70 : 130}
                        fill="#8884d8"
                        dataKey="value"
                        label={renderCustomizedLabel}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colorSet[index % colorSet.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </Paper>
    );

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 768) {
                setMarginLeft('15vw');
            } else {
                setMarginLeft('10vw');
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? `calc(${marginLeft} + 20px)` : '0',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%',
                padding: '20px'
            }}>
                <Box sx={{ color: 'white', textAlign: 'center', mb: 2 }}>
                    <Typography variant="h4">Dashboard</Typography>
                </Box>
                <Box sx={{ color: 'white', textAlign: 'center', mb: 4 }}>
                    <Typography variant="h6">Entities Overview</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', gap: 2 }}>
                    {renderPieChart(opportunitiesData, 'Oportunitati', COLORS.opportunities)}
                    {renderPieChart(activitiesData, 'Activitati', COLORS.activities)}
                    {renderPieChart(ticketsData, 'Tichete', COLORS.tickets)}
                </Box>
            </div>
        </div>
    );
}

export default HomePage;
