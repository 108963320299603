import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { useAuth } from '../../API/AuthContext';

const Navbar = ({ isNavbarVisible }) => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();

    const getLinkClassName = (basePath) => {
        return location.pathname.startsWith(basePath) ? 'navbar-link active' : 'navbar-link';
    };

    const isSettingsActive = () => {
        const settingsPaths = ['/settings', '/account', '/users', '/products'];
        return settingsPaths.some(path => location.pathname.startsWith(path)) ? 'navbar-link active' : 'navbar-link';
    };

    if (!isAuthenticated) {
        return null;
    }

    return (
        <nav className={`navbar ${isNavbarVisible ? 'navbar-visible' : 'navbar-hidden'}`}>
            <Link className={getLinkClassName('/companies')} to="/companies">Companii</Link>
            <Link className={getLinkClassName('/contacts')} to="/contacts">Contacte</Link>
            <Link className={getLinkClassName('/activities')} to="/activities">Activitati</Link>
            <Link className={getLinkClassName('/opportunities')} to="/opportunities">Oportunitati</Link>
            <Link className={getLinkClassName('/tickets')} to="/tickets">Tichete</Link>
            <Link className={isSettingsActive()} to="/settings">Setari</Link>
        </nav>
    );
};

export default Navbar;
