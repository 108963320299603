import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../API/AuthContext";
import { Box, Button, Typography, Container, CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backgroundImage from './background.jpg';

const useStyles = makeStyles({
    root: {
        minHeight: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        position: 'relative',
        padding: '16px',
        overflow: 'hidden',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    content: {
        position: 'relative',
        zIndex: 1,
    }
});

const disableScroll = () => {
    document.body.style.overflow = 'hidden';
};

const enableScroll = () => {
    document.body.style.overflow = 'auto';
};

const FrontPage = () => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const classes = useStyles();

    const handleLoginClick = () => {
        if (isAuthenticated) {
            navigate('/home');
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        disableScroll();
    }, []);

    return (
        <Box className={classes.root}>
            <CssBaseline />
            <Box className={classes.overlay} />
            <Container className={classes.content}>
                <Typography
                    variant="h1"
                    component="h1"
                    gutterBottom
                    style={{ fontWeight: 'bold', fontSize: '3rem' }}
                >
                    TTRACKER
                </Typography>
                <Typography variant="h6" paragraph style={{ fontSize: '1rem' }}>
                    "Ușurează-ți fluxul de lucru fără efort. Organizează sarcinile, urmărește progresul și obține mai mult. Simplifică colaborarea în echipă. Totul într-o singură aplicație"
                </Typography>
                <Button variant="contained" color="primary" onClick={handleLoginClick}>
                    Începe
                </Button>
            </Container>
        </Box>
    );
};

export default FrontPage;
