import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Alert, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { addUser, updateUserPhoto } from '../../API/UserAPI';
import { useAuth } from "../../API/AuthContext";

const AddUserFormDialog = ({ open, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('USER');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [photo, setPhoto] = useState(null);
    const [alert, setAlert] = useState({ severity: '', message: '' });
    const { user } = useAuth();
    const isDesktop = useMediaQuery('(min-width:600px)');

    const handleSave = async () => {
        if (password !== confirmPassword) {
            setAlert({ severity: 'error', message: 'Parolele nu sunt identice' });
            return;
        }
        const userData = { name, email, role, password, accountId: user.accountId };
        try {
            const newUser = await addUser(userData);
            if (photo) {
                await updateUserPhoto(newUser.id, photo);
            }
            setAlert({ severity: 'success', message: 'Utilizator adăugat cu succes' });
            onSave();
            handleClose();
        } catch (error) {
            setAlert({ severity: 'error', message: 'Utilizatorul nu a fost adăugat' });
        }
    };

    const handleClose = () => {
        setName('');
        setEmail('');
        setRole('USER');
        setPassword('');
        setConfirmPassword('');
        setPhoto(null);
        setAlert({ severity: '', message: '' });
        onClose();
    };

    const getRoleDescription = (role) => {
        if (role === 'ADMIN') {
            return (
                <Typography variant="body2" color="textSecondary">
                    Poate crea utilizatori.<br />
                    Poate edita datele utilizatorilor.<br />
                    Poate crea date.<br />
                    Poate edita date.<br />
                    Poate șterge date.
                </Typography>
            );
        } else if (role === 'USER') {
            return (
                <Typography variant="body2" color="textSecondary">
                    Poate crea date.<br />
                    Poate edita date.
                </Typography>
            );
        }
        return null;
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Adaugă Utilizator</DialogTitle>
            <DialogContent>
                {alert.message && (
                    <Alert severity={alert.severity} onClose={() => setAlert({ severity: '', message: '' })}>
                        {alert.message}
                    </Alert>
                )}
                <Stack spacing={2} sx={{ minWidth: isDesktop ? '500px' : 'auto' }}>
                    <TextField label="Nume" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
                    <TextField label="Parola" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth />
                    <TextField label="Confirmă Parola" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} fullWidth />
                    <TextField select label="Rol" value={role} onChange={(e) => setRole(e.target.value)} fullWidth>
                        <MenuItem value="ADMIN">Administrator</MenuItem>
                        <MenuItem value="USER">Utilizator</MenuItem>
                    </TextField>
                    {getRoleDescription(role)}
                    <input type="file" accept="image/*" onChange={(e) => setPhoto(e.target.files[0])} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Anulează</Button>
                <Button onClick={handleSave}>Salvează</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserFormDialog;
