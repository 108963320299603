import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography, Avatar, useMediaQuery, IconButton, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Header from "../../../Components/Header/Header";
import Navbar from "../../../Components/Navbar/Navbar";
import AddContactFormDialog from '../../../Components/Contacts/AddContactFormDialog';
import EditContactFormDialog from '../../../Components/Contacts/EditContactFormDialog';
import userImage from './user.png';
import { fetchContacts, addContact, deleteContact, updateContact } from "../../../API/ContactAPI";
import { useAuth } from '../../../API/AuthContext';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';

const ContactsPage = () => {
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [selectedContactIds, setSelectedContactIds] = useState([]);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { user } = useAuth();

    const loadContacts = async () => {
        try {
            const data = await fetchContacts(user.accountId);
            setContacts(data);
            setFilteredContacts(data);
        } catch (error) {
            console.error('Failed to load contacts:', error);
        }
    };

    useEffect(() => {
        if (user) {
            loadContacts();
        }
    }, [user]);

    const handleSearch = () => {
        const filtered = contacts.filter(contact =>
            contact.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredContacts(filtered);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (contact) => {
        setSelectedContact(contact);
        setEditDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteContact(id).then(loadContacts).catch(console.error);
    };

    const handleDeleteClick = (contact) => {
        setContactToDelete(contact);
        setDeleteDialogOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteDialogOpen(true);
    };

    const handleBulkDeleteConfirm = () => {
        Promise.all(selectedContactIds.map(id => deleteContact(id)))
            .then(loadContacts)
            .catch(console.error)
            .finally(() => {
                setBulkDeleteDialogOpen(false);
                setSelectedContactIds([]);
            });
    };

    const handleAddContact = (contactData) => {
        addContact(contactData).then(loadContacts).catch(console.error);
        setAddDialogOpen(false);
    };

    const handleUpdateContact = (contactData) => {
        updateContact(contactData.id, contactData).then(loadContacts).catch(console.error);
        setEditDialogOpen(false);
    };

    const handleCellClick = (params, event) => {
        if (params.field !== '__check__') {
            event.stopPropagation();
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: <strong>Nume contact</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/contacts/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'assignedPersonName', headerName: <strong>Responsabil</strong>, flex: 1, sortable: true },
        {
            field: 'companyName',
            headerName: <strong>Companie asociata</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/companies/${params.row.companyId}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'function', headerName: <strong>Functie</strong>, flex: 1, sortable: true },
        { field: 'phoneNumber', headerName: <strong>Telefon</strong>, flex: 1, sortable: true },
        { field: 'email', headerName: <strong>Email</strong>, flex: 1, sortable: true },
        {
            field: 'actions',
            headerName: <strong>Actiuni</strong>,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={user.role == 'USER'} color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const mobileColumns = [
        {
            field: 'name',
            headerName: 'Nume',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/contacts/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actiuni',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={user.role == 'USER'} color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Header
                isNavbarVisible={isNavbarVisible}
                setIsNavbarVisible={setIsNavbarVisible}
                onRefreshContacts={loadContacts}
            />

            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? isMobile? '80px' : `calc(10vw)` : isMobile? '0px' : '0px',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{marginLeft: isMobile ? '25px' : '30px', height: '100%', width: 'calc(100% - 40px)'}}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Avatar alt="s" src={userImage} />
                            <Typography variant="h5">Contacte</Typography>
                            <Button variant="contained" onClick={() => setAddDialogOpen(true)}>Adauga</Button>
                            {isDesktop && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        id="outlined-basic"
                                        label="Cauta"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            endIcon={<DeleteForeverIcon />}
                            onClick={handleBulkDeleteClick}
                            size="small"
                            style={{ marginBottom: '10px', padding: '4px 8px', fontSize: '10px'}}
                        >
                            Sterge selectia
                        </Button>
                        <DataGrid
                            rows={filteredContacts}
                            columns={isMobile ? mobileColumns : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowSelectionModelChange={(newSelection) => {
                                setSelectedContactIds(newSelection);
                            }}
                            onCellClick={handleCellClick}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            disableExtendRowFullWidth
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell--editable': {
                                    outline: 'none',
                                    border: 'none',
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid lightgray",
                                    padding: "5px",
                                    backgroundColor: "white",
                                    width: "calc(100% - 2px)",
                                }
                            }}
                            localeText={{
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Pe pagina',
                                },
                                noRowsLabel: "Nici o inregistrare",
                                toolbarDensity: {
                                    density: 'Density',
                                    densityLabel: 'Density',
                                    densityCompact: 'Compact',
                                    densityStandard: 'Standard',
                                    densityComfortable: 'Comfortable',
                                },
                            }}
                            classes={{ root: 'custom-data-grid' }}
                        />
                    </div>
                </div>
            </div>
            <AddContactFormDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSave={handleAddContact}
            />
            <EditContactFormDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleUpdateContact}
                contact={selectedContact}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={() => {
                    handleDelete(contactToDelete.id);
                    setDeleteDialogOpen(false);
                }}
                message="Sunteti sigur ca vreti sa stergeti contactul?"
            />
            <ConfirmationDialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                onConfirm={handleBulkDeleteConfirm}
                message="Sunteti sigur ca vreti sa stergeti contactele selectate?"
            />
        </div>
    );
};

export default ContactsPage;
