import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Stack,
    MenuItem,
    Select
} from '@mui/material';
import { useAuth } from '../../API/AuthContext';

const AddProductFormDialog = ({ open, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [productCode, setProductCode] = useState('');
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('');
    const [unitOfMeasure, setUnitOfMeasure] = useState('');
    const [tax, setTax] = useState('');
    const [status, setStatus] = useState('Yes');
    const { user } = useAuth();

    const handleSave = () => {
        const productData = {
            name,
            description,
            productCode,
            price,
            currency,
            unitOfMeasure,
            tax,
            status,
            accountId: user.accountId
        };
        onSave(productData);
        handleClose();
    };

    const handleClose = () => {
        setName('');
        setDescription('');
        setProductCode('');
        setPrice('');
        setCurrency('');
        setUnitOfMeasure('');
        setTax('');
        setStatus('Yes');
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Adauga produs</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField label="Nume" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    <TextField
                        label="Descriere"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        minRows={3}
                    />
                    <TextField label="Cod produs" value={productCode} onChange={(e) => setProductCode(e.target.value)} fullWidth />
                    <TextField label="Pret unitar" value={price} onChange={(e) => setPrice(e.target.value)} fullWidth />
                    <TextField label="Valuta" value={currency} onChange={(e) => setCurrency(e.target.value)} fullWidth />
                    <TextField label="Unitate de masura" value={unitOfMeasure} onChange={(e) => setUnitOfMeasure(e.target.value)} fullWidth />
                    <TextField label="TVA%" value={tax} onChange={(e) => setTax(e.target.value)} fullWidth />
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>In stoc</MenuItem>
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                        <MenuItem value="FURNIZOR">FURNIZOR</MenuItem>
                    </Select>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Anuleaza</Button>
                <Button onClick={handleSave} color="primary">Salveaza</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProductFormDialog;
