import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/opportunities';

export const fetchOpportunities = async (accountId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching opportunities:', error);
        throw error;
    }
};

export const addOpportunity = async (opportunityData) => {
    try {
        const response = await axios.post(BASE_URL, opportunityData);
        return response.data;
    } catch (error) {
        console.error('Error adding opportunity:', error);
        throw error;
    }
};

export const updateOpportunity = async (id, opportunityData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, opportunityData);
        return response.data;
    } catch (error) {
        console.error('Error updating opportunity:', error);
        throw error;
    }
};

export const deleteOpportunity = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting opportunity:', error);
        throw error;
    }
};

export const fetchOpportunitiesForCompany = async (companyId) => {
    try {
        const response = await axios.get(`${BASE_URL}/companies`, {
            params: { companyId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching opportunities:', error);
        throw error;
    }
};

export const fetchOpportunitiesForContact = async (contactId) => {
    try {
        const response = await axios.get(`${BASE_URL}/contacts`, {
            params: { contactId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching opportunities:', error);
        throw error;
    }
};
export const fetchOpportunityDetails = async (opportunityId) => {
    const response = await axios.get(`${BASE_URL}/${opportunityId}`);
    return response.data;
};