import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/contacts';

export const fetchContacts = async (accountId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching contacts:', error);
        throw error;
    }
};

export const deleteContact = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting contact:', error);
        throw error;
    }
};

export const addContact = async (contactData) => {
    try {
        const response = await axios.post(BASE_URL, contactData);
        return response.data;
    } catch (error) {
        console.error('Error adding contact:', error);
        throw error;
    }
};

export const updateContact = async (id, contactData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, contactData);
        return response.data;
    } catch (error) {
        console.error('Error updating contact:', error);
        throw error;
    }
};

export const fetchContactsForCompany = async (companyId) => {
    try {
        const response = await axios.get(`${BASE_URL}/companies`, {
            params: { companyId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching contacts:', error);
        throw error;
    }
};

export const fetchContactDetails = async (contactId) => {
    const response = await axios.get(`${BASE_URL}/${contactId}`);
    return response.data;
};