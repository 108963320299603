import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography, Avatar, useMediaQuery, IconButton, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Header from "../../../Components/Header/Header";
import Navbar from "../../../Components/Navbar/Navbar";
import AddOpportunityFormDialog from '../../../Components/Opportunities/AddOpportunityFormDialog';
import EditOpportunityFormDialog from '../../../Components/Opportunities/EditOpportunityFormDialog';
import opportunityImage from './opportunity.png';
import { fetchOpportunities, addOpportunity, updateOpportunity, deleteOpportunity } from "../../../API/OpportunityAPI";
import { fetchContacts } from "../../../API/ContactAPI";
import { fetchCompanies } from "../../../API/CompanyAPI";
import { fetchUsers } from "../../../API/UserAPI";
import { useAuth } from '../../../API/AuthContext';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';

const OpportunitiesPage = () => {
    const [opportunities, setOpportunities] = useState([]);
    const [filteredOpportunities, setFilteredOpportunities] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedOpportunity, setSelectedOpportunity] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [opportunityToDelete, setOpportunityToDelete] = useState(null);
    const [selectedOpportunityIds, setSelectedOpportunityIds] = useState([]);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { user } = useAuth();

    const loadOpportunities = async () => {
        try {
            const opportunitiesData = await fetchOpportunities(user.accountId);
            const contactsData = await fetchContacts(user.accountId);
            const companiesData = await fetchCompanies(user.accountId);
            const usersData = await fetchUsers(user.accountId);

            setContacts(contactsData);
            setCompanies(companiesData);
            setUsers(usersData);

            const enhancedOpportunities = opportunitiesData.map(opportunity => {
                let contactName = null;
                let contactId = null;
                let companyName = null;
                let companyId = null;

                if (opportunity.associatedEntityType === 'Contact') {
                    const contact = contactsData.find(c => c.id === opportunity.associatedEntityId);
                    if (contact) {
                        contactName = contact.name;
                        contactId = contact.id;
                        const company = companiesData.find(c => c.id === contact.companyId);
                        if (company) {
                            companyName = company.name;
                            companyId = company.id;
                        }
                    }
                } else if (opportunity.associatedEntityType === 'Company') {
                    const company = companiesData.find(c => c.id === opportunity.associatedEntityId);
                    if (company) {
                        companyName = company.name;
                        companyId = company.id;
                        const contact = contactsData.find(c => c.companyId === company.id);
                        if (contact) {
                            contactName = contact.name;
                            contactId = contact.id;
                        }
                    }
                }
                let assignedPersonNames = [];
                if (opportunity.userIds && opportunity.userIds.length > 0) {
                    assignedPersonNames = usersData.filter(user => opportunity.userIds.includes(user.id)).map(user => user.name);
                }

                return {
                    ...opportunity,
                    contactName,
                    contactId,
                    companyName,
                    companyId,
                    assignedPersonNames
                };
            });

            setOpportunities(enhancedOpportunities);
            setFilteredOpportunities(enhancedOpportunities);
        } catch (error) {
            console.error('Failed to load opportunities:', error);
        }
    };

    useEffect(() => {
        if (user) {
            loadOpportunities();
        }
    }, [user]);

    const handleSearch = () => {
        const filtered = opportunities.filter(opportunity =>
            opportunity.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredOpportunities(filtered);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (opportunity) => {
        setSelectedOpportunity(opportunity);
        setEditDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteOpportunity(id).then(loadOpportunities).catch(console.error);
    };

    const handleDeleteClick = (opportunity) => {
        setOpportunityToDelete(opportunity);
        setDeleteDialogOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteDialogOpen(true);
    };

    const handleBulkDeleteConfirm = () => {
        Promise.all(selectedOpportunityIds.map(id => deleteOpportunity(id)))
            .then(loadOpportunities)
            .catch(console.error)
            .finally(() => {
                setBulkDeleteDialogOpen(false);
                setSelectedOpportunityIds([]);
            });
    };

    const handleAddOpportunity = (opportunityData) => {
        addOpportunity(opportunityData).then(loadOpportunities).catch(console.error);
        setAddDialogOpen(false);
    };

    const handleEditOpportunity = (opportunityData) => {
        updateOpportunity(opportunityData.id, opportunityData).then(loadOpportunities).catch(console.error);
        setEditDialogOpen(false);
    };

    const handleCellClick = (params, event) => {
        if (params.field !== '__check__') {
            event.stopPropagation();
        }
    };

    const columns = [
        {
            field: 'id',
            headerName: <strong>#</strong>,
            flex: 1,
            maxWidth: 60,
            sortable: true
        },
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/opportunities/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'contactName',
            headerName: <strong>Contact</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/contacts/${params.row.contactId}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'companyName',
            headerName: <strong>Companie</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/companies/${params.row.companyId}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'value', headerName: <strong>Valoare (RON)</strong>, flex: 2, sortable: true },
        { field: 'probability', headerName: <strong>Sansa (%)</strong>, flex: 1, sortable: true },
        { field: 'startDate', headerName: <strong>Data start</strong>, flex: 2, sortable: true },
        { field: 'finishDate', headerName: <strong>Data final</strong>, flex: 2, sortable: true },
        {
            field: 'assignedPersonNames',
            headerName: <strong>Responsabili</strong>,
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            ),
        },
        { field: 'status', headerName: <strong>Status</strong>, flex: 2, sortable: true },
        {
            field: 'actions',
            headerName: <strong>Actiuni</strong>,
            flex: 2,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={user.role === 'USER'} color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const mobileColumns = [
        {
            field: 'name',
            headerName: 'Nume',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/opportunities/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actiuni',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={user.role === 'USER'} color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Header
                isNavbarVisible={isNavbarVisible}
                setIsNavbarVisible={setIsNavbarVisible}
                onRefreshOpportunities={loadOpportunities}
            />

            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? isMobile? '80px' : `calc(10vw)` : isMobile? '0px' : '0px',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{marginLeft: isMobile ? '25px' : '30px', height: '100%', width: 'calc(100% - 40px)'}}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Avatar alt="s" src={opportunityImage} />
                            <Typography variant="h5">Oportunitati</Typography>
                            {isDesktop && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)}>Adauga</Button>
                            )}
                            {isDesktop && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        id="outlined-basic"
                                        label="Cauta"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                        {isMobile && (
                            <Button variant="contained" onClick={() => setAddDialogOpen(true)} style={{ marginTop: '10px' }}>
                                Adauga
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            endIcon={<DeleteForeverIcon />}
                            onClick={handleBulkDeleteClick}
                            size="small"
                            style={{ marginBottom: '10px', padding: '4px 8px', fontSize: '10px' }}
                        >
                            Sterge selectia
                        </Button>
                        <DataGrid
                            rows={filteredOpportunities}
                            columns={isMobile ? mobileColumns : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                                sorting: {
                                    sortModel: [{ field: 'id', sort: 'asc' }],
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowSelectionModelChange={(newSelection) => {
                                setSelectedOpportunityIds(newSelection);
                            }}
                            onCellClick={handleCellClick}
                            autoHeight
                            disableExtendRowFullWidth
                            getRowHeight={() => 'auto'}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell--editable': {
                                    outline: 'none',
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    overflow: 'visible',
                                    whiteSpace: 'normal',
                                    lineHeight: 'normal',
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid lightgray",
                                    padding: "5px",
                                    backgroundColor: "white",
                                    width: "calc(100% - 2px)",
                                }
                            }}
                            localeText={{
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Pe pagina',
                                },
                                noRowsLabel: "Nici o inregistrare",
                                toolbarDensity: {
                                    density: 'Density',
                                    densityLabel: 'Density',
                                    densityCompact: 'Compact',
                                    densityStandard: 'Standard',
                                    densityComfortable: 'Comfortable',
                                },
                            }}
                            classes={{ root: 'custom-data-grid' }}
                        />
                    </div>
                </div>
            </div>
            <AddOpportunityFormDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSave={handleAddOpportunity}
            />
            <EditOpportunityFormDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleEditOpportunity}
                opportunity={selectedOpportunity}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={() => {
                    handleDelete(opportunityToDelete.id);
                    setDeleteDialogOpen(false);
                }}
                message="Sunteti sigur ca vreti sa stergeti oportunitatea?"
            />
            <ConfirmationDialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                onConfirm={handleBulkDeleteConfirm}
                message="Sunteti sigur ca vreti sa stergeti oportunitatile selectate?"
            />
        </div>
    );
};

export default OpportunitiesPage;
