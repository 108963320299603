import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/companies';

export const fetchCompanies = async (accountId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};

export const deleteCompany = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting company:', error);
        throw error;
    }
};

export const addCompany = async (companyData) => {
    try {
        const response = await axios.post(BASE_URL, companyData);
        return response.data;
    } catch (error) {
        console.error('Error adding company:', error);
        throw error;
    }
};

export const updateCompany = async (id, companyData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, companyData);
        return response.data;
    } catch (error) {
        console.error('Error updating company:', error);
        throw error;
    }
};

export const fetchCompanyDetails = async (companyId) => {
    const response = await axios.get(`${BASE_URL}/${companyId}`);
    return response.data;
};
