import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Autocomplete,
    Stack,
    Box,
    Typography,
    List,
    ListItem,
    Paper,
    Popper,
    IconButton,
    MenuItem, Select
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { fetchUsers } from '../../API/UserAPI';
import { fetchCompanies } from '../../API/CompanyAPI';
import { fetchContacts } from '../../API/ContactAPI';
import { Phone as PhoneIcon, ChatBubble as ChatBubbleIcon, CheckBox as CheckBoxIcon, Folder as FolderIcon } from '@mui/icons-material';
import { useAuth } from "../../API/AuthContext";
import dayjs from 'dayjs';

const AddActivityForEntityFormDialog = ({ open, onClose, onSave, entity, subactivityEntityId, subactivityEntityType }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(dayjs());
    const [finishDate, setFinishDate] = useState(dayjs());
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [products, setProducts] = useState('');
    const [assignedPersons, setAssignedPersons] = useState([]);
    const [contact, setContact] = useState(null);
    const [company, setCompany] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [entities, setEntities] = useState([]);
    const [persons, setPersons] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { user } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const personsData = await fetchUsers(user.accountId);
                setPersons(personsData);

                const companiesData = await fetchCompanies(user.accountId);
                setCompanies(companiesData);

                const contactsData = await fetchContacts(user.accountId);
                setContacts(contactsData);

                const combinedData = [
                    ...companiesData.map((company) => ({ ...company, type: 'Companii' })),
                    ...contactsData.map((contact) => ({ ...contact, type: 'Contacte' }))
                ];
                setEntities(combinedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user]);

    useEffect(() => {
        if (entity) {
            setAssignedPersons(persons.filter(person => entity.userIds.includes(person.id)));
            if (entity.associatedEntityType === 'Contact') {
                setContact(contacts.find(contact => contact.id === entity.associatedEntityId));
                setCompany(null);
            } else if (entity.associatedEntityType === 'Company') {
                setCompany(companies.find(company => company.id === entity.associatedEntityId));
                setContact(null);
            }
        }
    }, [entity, persons, contacts, companies]);

    const CustomPaper = (props) => (
        <Paper {...props} style={{ maxHeight: 200, overflow: 'auto' }} />
    );

    const handleSave = () => {
        if (startDate.isAfter(finishDate)) {
            setErrorMessage('Data start trebuie să fie înainte de data final.');
            return;
        }
        const activityData = {
            name,
            description,
            startDate: startDate.format('YYYY-MM-DD HH:mm'),
            finishDate: finishDate.format('YYYY-MM-DD HH:mm'),
            status,
            type,
            products,
            userIds: assignedPersons.map(person => person.id),
            associatedEntityId: contact ? contact.id : company ? company.id : null,
            associatedEntityType: contact ? 'Contact' : company ? 'Company' : null,
            subactivityEntityId: subactivityEntityId,
            subactivityEntityType: subactivityEntityType,
            accountId: user.accountId
        };
        onSave(activityData);
        handleClose();
    };

    const handleClose = () => {
        setName('');
        setDescription('');
        setStartDate(dayjs());
        setFinishDate(dayjs());
        setStatus('');
        setType('');
        setProducts('');
        setAssignedPersons([]);
        setContact(null);
        setCompany(null);
        onClose();
    };

    const CustomPopper = (props) => {
        const { children, ...rest } = props;
        if (React.Children.count(children) === 0) {
            return null;
        }
        return <Popper {...rest}>{children}</Popper>;
    };

    const getDialogTitle = () => {
        if (!subactivityEntityId) {
            return 'Adauga activitate';
        }

        switch (subactivityEntityType) {
            case 'Ticket':
                return `Adauga activitate pentru Tichetul #${subactivityEntityId}`;
            case 'Opportunity':
                return `Adauga activitate pentru Oportunitatea #${subactivityEntityId}`;
            case 'Activity':
                return `Adauga activitate pentru Activitatea #${subactivityEntityId}`;
            default:
                return 'Adauga activitate';
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>{getDialogTitle()}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Box textAlign="center">
                            <IconButton onClick={() => setType('Apel')}>
                                <PhoneIcon color={type === 'Apel' ? 'primary' : 'default'} />
                            </IconButton>
                            <Typography variant="h7">Apel</Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton onClick={() => setType('Intalnire')}>
                                <ChatBubbleIcon color={type === 'Intalnire' ? 'primary' : 'default'} />
                            </IconButton>
                            <Typography variant="h7">Intalnire</Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton onClick={() => setType('Activitate')}>
                                <CheckBoxIcon color={type === 'Activitate' ? 'primary' : 'default'} />
                            </IconButton>
                            <Typography variant="h7">Activitate</Typography>
                        </Box>
                        <Box textAlign="center">
                            <IconButton onClick={() => setType('Altele')}>
                                <FolderIcon color={type === 'Altele' ? 'primary' : 'default'} />
                            </IconButton>
                            <Typography variant="h7">Altele</Typography>
                        </Box>
                    </Stack>
                    <TextField label="Nume" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    <TextField label="Descriere" value={description} onChange={(e) => setDescription(e.target.value)} fullWidth multiline minRows={3} />
                    <DateTimePicker
                        label="Data start"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                    <DateTimePicker
                        label="Data final"
                        value={finishDate}
                        onChange={(newValue) => setFinishDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>Status</MenuItem>
                        <MenuItem value="Programata">Programata</MenuItem>
                        <MenuItem value="In curs">In curs</MenuItem>
                        <MenuItem value="Finalizata">Finalizata</MenuItem>
                    </Select>
                    <TextField
                        label="Produse"
                        value={products}
                        onChange={(e) => setProducts(e.target.value)}
                        fullWidth
                    />
                    <Autocomplete
                        multiple
                        options={persons}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Persoane responsabile" />}
                        value={assignedPersons}
                        onChange={(event, newValue) => setAssignedPersons(newValue)}
                        fullWidth
                    />
                    <Autocomplete
                        options={entities}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, { inputValue }) => {
                            if (inputValue === '') {
                                return [];
                            }
                            return options.filter((option) =>
                                option.name.toLowerCase().includes(inputValue.toLowerCase())
                            );
                        }}
                        value={contact || company}
                        renderInput={(params) => <TextField {...params} label="Contact/Companie" variant="outlined" />}
                        renderOption={(props, option) => (
                            <ListItem {...props}>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <Typography variant="body1">
                                        {option.name}
                                    </Typography>
                                </Box>
                            </ListItem>
                        )}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                if (newValue.type === 'Contacte') {
                                    setContact(newValue);
                                    setCompany(null);
                                } else if (newValue.type === 'Companii') {
                                    setCompany(newValue);
                                    setContact(null);
                                } else {
                                    setContact(null);
                                    setCompany(null);
                                }
                            } else {
                                setContact(null);
                                setCompany(null);
                            }
                        }}
                        PaperComponent={CustomPaper}
                        ListboxComponent={(listboxProps) => (
                            <List {...listboxProps} style={{ maxHeight: 200, overflow: 'auto' }} />
                        )}
                        PopperComponent={CustomPopper}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        noOptionsText="No options"
                    />
                    {errorMessage && (
                        <Typography color="error">{errorMessage}</Typography>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Anuleaza</Button>
                <Button onClick={handleSave} color="primary">Salveaza</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddActivityForEntityFormDialog;
