import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/users';

export const fetchUsers = async (accountId) => {
    try {
        const response = await axios.get(BASE_URL, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const fetchUserDetails = async (accountId) => {
    try {
        const response = await axios.get(`${BASE_URL}/details`, {
            params: { accountId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};

export const addUser = async (userData) => {
    try {
        const response = await axios.post(BASE_URL, userData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error adding user:', error);
        throw error;
    }
};

export const updateUser = async (id, userData) => {
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, userData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};

export const updateUserPhoto = async (id, photo) => {
    try {
        const formData = new FormData();
        formData.append('photo', photo);

        const response = await axios.post(`${BASE_URL}/${id}/photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating user photo:', error);
        throw error;
    }
};

export const deleteUser = async (id) => {
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Error deleting user:', error);
        throw error;
    }
};
