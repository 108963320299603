import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography, Avatar, useMediaQuery, IconButton, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';
import Header from "../../../Components/Header/Header";
import Navbar from "../../../Components/Navbar/Navbar";
import AddUserFormDialog from '../../../Components/Users/AddUserFormDialog';
import EditUserFormDialog from '../../../Components/Users/EditUserFormDialog';
import { fetchUserDetails, deleteUser, updateUser, addUser, updateUserPhoto } from "../../../API/UserAPI";
import { useAuth } from '../../../API/AuthContext';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import defaultAvatar from './default-avatar.png';
import { useNavigate } from "react-router-dom";
import settingsImage from "../../OtherPages/AccountPage/settings-gear2.png";
import usersImage from "./people.png";

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { user } = useAuth();
    const navigate = useNavigate();

    const loadUsers = async () => {
        try {
            const usersData = await fetchUserDetails(user.accountId);
            const sortedUsers = usersData.sort((a, b) => {
                if (a.role === 'OWNER') return -1;
                if (b.role === 'OWNER') return 1;
                if (a.role === 'ADMIN') return -1;
                if (b.role === 'ADMIN') return 1;
                return 0;
            });
            setUsers(sortedUsers);
            setFilteredUsers(sortedUsers);
        } catch (error) {
            console.error('Failed to load users:', error);
        }
    };

    useEffect(() => {
        if (user) {
            loadUsers();
        }
    }, [user]);

    const handleSearch = () => {
        const filtered = users.filter(user =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setEditDialogOpen(true);
    };

    const handleDelete = async (id) => {
        deleteUser(id).then(loadUsers).catch(console.error);
    };

    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setDeleteDialogOpen(true);
    };

    const handleAddUser = async () => {
        try {
            await loadUsers();
            setAddDialogOpen(false);
        } catch (error) {
            console.error('Failed to load users:', error);
        }
    };

    const handleEditUser = async (userData, photo) => {
        try {
            await updateUser(userData.id, userData);
            if (photo) {
                await updateUserPhoto(userData.id, photo);
            }
            await loadUsers();
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Failed to edit user:', error);
        }
    };

    const handleCellClick = (params, event) => {
        if (params.field !== '__check__') {
            event.stopPropagation();
        }
    };

    const getPermissionsText = (role) => {
        if (role === 'OWNER') {
            return (
                <>
                    Poate crea utilizatori. <br />
                    Poate edita datele utilizatorilor. <br />
                    Poate crea date. <br />
                    Poate edita date. <br />
                    Poate sterge date. <br />
                    Poate edita contul.
                </>
            );
        } else if (role === 'ADMIN') {
            return (
                <>
                    Poate crea utilizatori. <br />
                    Poate edita datele utilizatorilor. <br />
                    Poate crea date. <br />
                    Poate edita date. <br />
                    Poate sterge date.
                </>
            );
        } else if (role === 'USER') {
            return (
                <>
                    Poate crea date. <br />
                    Poate edita date.
                </>
            );
        }
    };

    const columns = [
        {
            field: 'rowNumber',
            headerName: <strong>#</strong>,
            flex: 0.5,
            maxWidth: 60,
            sortable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 + "."
        },
        {
            field: 'profilePicture', headerName: <strong>Imagine</strong>, flex: 1, sortable: false,
            renderCell: (params) => (
                <Avatar
                    alt={params.row.name}
                    src={params.row.profilePicture ? `data:${params.row.profilePicture.type};base64,${params.row.profilePicture.data}` : defaultAvatar}
                />
            )
        },
        { field: 'name', headerName: <strong>Nume</strong>, flex: 1, sortable: true },
        { field: 'email', headerName: <strong>Email</strong>, flex: 1, sortable: true },
        {
            field: 'role', headerName: <strong>Rol</strong>, flex: 1, sortable: true,
            renderCell: (params) => {
                switch (params.value) {
                    case 'OWNER':
                        return "Proprietar cont";
                    case 'ADMIN':
                        return "Administrator";
                    case 'USER':
                        return "Utilizator";
                    default:
                        return params.value;
                }
            }
        },
        {
            field: 'permissions', headerName: <strong>Permisiuni</strong>, flex: 2, sortable: false,
            renderCell: (params) => (
                <div>
                    {getPermissionsText(params.row.role)}
                </div>
            )
        },
        { field: 'lastConnected', headerName: <strong>Ultima conectare</strong>, flex: 1, sortable: true },
        {
            field: 'actions', headerName: <strong>Actiuni</strong>, flex: 1, sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    {(user.role === 'ADMIN' || user.role === 'OWNER') && params.row.role !== 'OWNER' && (
                        <>
                            <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                    {(user.role === 'USER') && params.row.role !== 'OWNER' && (
                        <>
                            <IconButton color="primary" onClick={() => handleEdit(params.row)} disabled>
                                <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)} disabled>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </Stack>
            )
        }
    ];

    const mobileColumns = [
        {
            field: 'userDetails', headerName: 'Utilizatori', flex: 1, sortable: true,
            renderCell: (params) => (
                <div>
                    <Avatar
                        alt={params.row.name}
                        src={params.row.profilePicture ? `data:${params.row.profilePicture.type};base64,${params.row.profilePicture.data}` : defaultAvatar}
                    />
                    <div><strong>Nume:</strong> {params.row.name}</div>
                    <div><strong>Email:</strong> {params.row.email}</div>
                    <div><strong>Rol:</strong> {(() => {
                        switch (params.row.role) {
                            case 'OWNER':
                                return "Proprietar cont";
                            case 'ADMIN':
                                return "Administrator";
                            case 'USER':
                                return "Utilizator";
                            default:
                                return params.row.role;
                        }
                    })()}</div>
                    <div><strong>Permisiuni:</strong> <br />{getPermissionsText(params.row.role)}</div>
                    <div><strong>Ultima conectare:</strong> {params.row.lastConnected}</div>
                    {(user.role === 'ADMIN' || user.role === 'OWNER') && params.row.role !== 'OWNER' && (
                        <div>
                            <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                    {(user.role === 'USER') && params.row.role !== 'OWNER' && (
                        <div>
                            <IconButton color="primary" onClick={() => handleEdit(params.row)} disabled>
                                <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)} disabled>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            )
        }
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? isMobile? '80px' : `calc(10vw)` : isMobile? '0px' : '0px',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{marginLeft: isMobile ? '25px' : '30px', height: '100%', width: 'calc(100% - 40px)'}}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Avatar alt="s" src={settingsImage} onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }} />
                            <Typography variant="h4">/</Typography>
                            <Avatar alt="s" src={usersImage}></Avatar>
                            <Typography variant="h5">Utilizatori</Typography>
                            {isDesktop && (user.role === 'ADMIN' || user.role === 'OWNER') && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)}>Adauga</Button>
                            )}
                            {isDesktop && user.role === 'USER' && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)} disabled>Adauga</Button>
                            )}
                            {isDesktop && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        id="outlined-basic"
                                        label="Cauta"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                        {!isDesktop && (
                            <>
                                <Stack direction="column" alignItems="flex-start" spacing={2}>
                                    <Button variant="contained" onClick={() => setAddDialogOpen(true)} style={{ marginTop: '10px' }}>Adauga</Button>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <TextField
                                            id="outlined-basic"
                                            label="Cauta"
                                            variant="outlined"
                                            value={searchQuery}
                                            onChange={handleSearchInputChange}
                                            size="small"
                                        />
                                        <IconButton color="primary" onClick={handleSearch}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </>
                        )}
                    </div>
                    <div>
                        <DataGrid
                            rows={filteredUsers}
                            columns={isMobile ? mobileColumns : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                                sorting: {
                                    sortModel: [{ field: 'id', sort: 'desc' }],
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            disableSelectionOnClick
                            onCellClick={handleCellClick}
                            autoHeight
                            disableExtendRowFullWidth
                            getRowHeight={() => 'auto'}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell--editable': {
                                    outline: 'none',
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    overflow: 'visible',
                                    whiteSpace: 'normal',
                                    lineHeight: 'normal',
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid lightgray",
                                    padding: "5px",
                                    backgroundColor: "white",
                                    width: "calc(100% - 2px)",
                                }
                            }}
                            localeText={{
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Pe pagina',
                                },
                                noRowsLabel: "Nici o inregistrare",
                                toolbarDensity: {
                                    density: 'Density',
                                    densityLabel: 'Density',
                                    densityCompact: 'Compact',
                                    densityStandard: 'Standard',
                                    densityComfortable: 'Comfortable',
                                },
                            }}
                            classes={{ root: 'custom-data-grid' }}
                        />
                    </div>
                </div>
            </div>
            <AddUserFormDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSave={handleAddUser}
            />
            <EditUserFormDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleEditUser}
                user={selectedUser}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={() => {
                    handleDelete(userToDelete.id);
                    setDeleteDialogOpen(false);
                }}
                message="Sunteti sigur ca vreti sa stergeti utilizatorul? Actiunea este ireversibila!"
            />
        </div>
    );
};

export default UsersPage;
