import React, { useState, useEffect } from 'react';
import {Typography, useMediaQuery} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { fetchTicketsForCompany, fetchTicketsForContact } from '../../API/TicketAPI';

const TicketsForEntityTable = ({ entityId, entityType }) => {
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const loadTickets = async () => {
            try {
                let ticketsData = [];
                if (entityType === "Company")
                    ticketsData = await fetchTicketsForCompany(entityId);
                else if (entityType === "Contact")
                    ticketsData = await fetchTicketsForContact(entityId);
                setTickets(ticketsData);
                setFilteredTickets(ticketsData);
            } catch (error) {
                console.error('Failed to load tickets:', error);
            }
        };

        if (entityId) {
            loadTickets();
        }
    }, [entityId]);

    const columns = [
        {
            field: 'id',
            headerName: <strong>#</strong>,
            flex: 1,
            maxWidth: 60,
            sortable: true
        },
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/tickets/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'priority', headerName: <strong>Prioritate</strong>, flex: 1, sortable: true },
        { field: 'creationDate', headerName: <strong>Data start</strong>, flex: 1, sortable: true },
        { field: 'finishDate', headerName: <strong>Data inchidere</strong>, flex: 1, sortable: true },
        { field: 'status', headerName: <strong>Status</strong>, flex: 1, sortable: true },
        {
            field: 'userNames',
            headerName: <strong>Responsabili</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            ),
        }
    ];

    const mobileColumns = [
        {
            field: 'details',
            headerName: 'Detalii',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    <div><strong>#</strong> {params.row.id}</div>
                    <div>
                        <Link to={`/tickets/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                            <strong>Nume:</strong> {params.row.name}
                        </Link>
                    </div>
                    <div><strong>Prioritate:</strong> {params.row.priority}</div>
                    <div><strong>Data start:</strong> {params.row.creationDate}</div>
                    <div><strong>Data inchidere:</strong> {params.row.finishDate}</div>
                    <div><strong>Status:</strong> {params.row.status}</div>
                    <div>
                        <strong>Responsabili:</strong>
                        {params.row.userNames.map((name, index) => (
                            <div key={index}>{name}</div>
                        ))}
                    </div>
                </div>
            )
        }
    ];

    return (
        <div>
            <Typography variant="h5"><strong>Tichete</strong></Typography>
            <DataGrid
                rows={filteredTickets}
                columns={isMobile ? mobileColumns : columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                disableSelectionOnClick
                autoHeight
                disableExtendRowFullWidth
                getRowHeight={() => 'auto'}
                disableColumnMenu
                sx={{
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell--editable': {
                        outline: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid lightgray",
                        padding: "5px",
                        backgroundColor: "white",
                        width: "calc(100% - 2px)",
                    }
                }}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Pe pagina',
                    },
                    noRowsLabel: "Nici o inregistrare",
                    toolbarDensity: {
                        density: 'Density',
                        densityLabel: 'Density',
                        densityCompact: 'Compact',
                        densityStandard: 'Standard',
                        densityComfortable: 'Comfortable',
                    },
                }}
                classes={{ root: 'custom-data-grid' }}
            />
        </div>
    );
};

export default TicketsForEntityTable;
