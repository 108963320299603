import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Button, Stack, Typography, Avatar, Box, useMediaQuery, IconButton, Select, MenuItem } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import { fetchCompanyDetails, updateCompany, deleteCompany } from '../../../API/CompanyAPI';
import { useAuth } from '../../../API/AuthContext';
import companyImage from '../../ListPages/CompaniesPage/enterprise.png';
import TicketsForEntityTable from '../../../Components/Tickets/TicketsForEntityTable';
import EditCompanyFormDialog from '../../../Components/Companies/EditCompanyFormDialog';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import OpportunitiesForEntityTable from '../../../Components/Opportunities/OpportunitiesForEntityTable';
import ActivitiesForEntityTable from "../../../Components/Activities/ActivitiesForEntityTable";
import NotesComponent from "../../../Components/Notes/NoteComponent";
import ContactsForCompanyTable from "../../../Components/Contacts/ContactsForCompanyTable";

const CompanyPage = () => {
    const {companyId} = useParams();
    const [company, setCompany] = useState(null);
    const [selectedTab, setSelectedTab] = useState('notes');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        const loadCompanyDetails = async () => {
            try {
                const data = await fetchCompanyDetails(companyId);
                setCompany(data);
            } catch (error) {
                console.error('Failed to load company details:', error);
            }
        };

        loadCompanyDetails();
    }, [companyId]);

    const handleEditCompany = async (updatedCompany) => {
        try {
            const updatedData = await updateCompany(companyId, updatedCompany);
            setCompany(updatedData);
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Failed to update company:', error);
        }
    };

    const handleDeleteCompany = async () => {
        try {
            await deleteCompany(companyId);
            navigate('/companies');
        } catch (error) {
            console.error('Failed to delete company:', error);
        }
    };

    const handleTabChange = (event) => {
        setSelectedTab(event.target.value);
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    return (
        <div style={{display: 'flex'}}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible}/>
            <Navbar isNavbarVisible={isNavbarVisible}/>
            <div style={{
                marginLeft: isNavbarVisible ? `calc(10vw)` : '0',
                marginTop: '40px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{
                    marginLeft: isMobile ? '55px' : '10px',
                    height: '100%',
                    width: `calc(100% - ${isMobile ? '65px' : '30px'})`
                }}>
                    <div style={{padding: '10px', margin: '10px'}}>
                        <Box sx={{
                            padding: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                            marginBottom: 2,
                            overflow: 'hidden',
                            wordBreak: 'break-word'
                        }}>
                            {company && (
                                <>

                                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                                        <Avatar alt={company.name} src={companyImage} sx={{width: 56, height: 56}}/>
                                        <Box flexGrow={1} sx={{minWidth: '250px', maxWidth: '100%'}}>
                                            <Typography variant="h4"
                                                        sx={{wordBreak: 'break-word'}}>{company.name}</Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>CUI</strong>: {company.cuicode}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Email</strong>: {company.email}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Telefon</strong>: {company.phoneNumber}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Adresa</strong>: {company.address}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Grup</strong>: {company.companyGroup}
                                            </Typography>
                                            {isMobile && (
                                                <>
                                                    <Typography
                                                        variant="body1"><strong>Responsabili</strong>:</Typography>
                                                    {company.assignedPersonNames?.map((name, index) => (
                                                        <Typography key={index} variant="body1"
                                                                    sx={{wordBreak: 'break-word'}}>{name}</Typography>
                                                    ))}
                                                </>
                                            )}
                                            {!isMobile && (
                                                <>
                                                    {company.assignedPersonNames?.map((name, index) => (
                                                        <Typography key={index} variant="body1"
                                                                    sx={{wordBreak: 'break-word'}}><strong>Responsabili</strong>: {name}
                                                        </Typography>
                                                    ))}
                                                </>
                                            )}
                                        </Box>
                                        {!isMobile && (
                                            <Stack direction="row" spacing={1}>
                                                <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton color="secondary" disabled={user.role === 'USER'}
                                                            onClick={handleDeleteClick}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                    {isMobile && (
                                        <Stack direction="row" spacing={1} justifyContent="center" sx={{marginTop: 2}}>
                                            <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton color="secondary" disabled={user.role === 'USER'}
                                                        onClick={handleDeleteClick}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Box>
                        <Box sx={{
                            display: isMobile ? 'none' : 'left',
                            justifyContent: 'space-between',
                            marginBottom: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                        }}>
                            <Button variant={selectedTab === 'notes' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('notes')}
                                    sx={{m: 2}}>
                                Notite
                            </Button>
                            <Button variant={selectedTab === 'contacts' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('contacts')}
                                    sx={{m: 2}}>
                                Contacte
                            </Button>
                            <Button variant={selectedTab === 'activities' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('activities')}
                                    sx={{m: 2}}>
                                Activitati
                            </Button>
                            <Button variant={selectedTab === 'opportunities' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('opportunities')}
                                    sx={{m: 2}}>
                                Oportunitati
                            </Button>
                            <Button variant={selectedTab === 'tickets' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('tickets')}
                                    sx={{m: 2}}>
                                Tichete
                            </Button>
                        </Box>
                        {isMobile && (
                            <Select value={selectedTab} onChange={handleTabChange} fullWidth>
                                <MenuItem value="notes">Notite</MenuItem>
                                <MenuItem value="contacts">Contacte</MenuItem>
                                <MenuItem value="activities">Activitati</MenuItem>
                                <MenuItem value="opportunities">Oportunitati</MenuItem>
                                <MenuItem value="tickets">Tichete</MenuItem>
                            </Select>
                        )}
                        {isMobile && (
                            <Box mt={2}/>
                        )}
                        <Box>
                            {selectedTab === 'notes' && <NotesComponent entityId={companyId} entityType="Company"/>}
                            {selectedTab === 'contacts' && <ContactsForCompanyTable companyId={companyId}/>}
                            {selectedTab === 'activities' &&
                                <ActivitiesForEntityTable entityId={companyId} entityType="Company"/>}
                            {selectedTab === 'opportunities' &&
                                <OpportunitiesForEntityTable entityId={companyId} entityType="Company"/>}
                            {selectedTab === 'tickets' &&
                                <TicketsForEntityTable entityId={companyId} entityType="Company"/>}
                        </Box>
                    </div>
                </div>
            </div>
            {company && (
                <>
                    <EditCompanyFormDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        onSave={handleEditCompany}
                        company={company}
                    />
                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={handleDeleteCompany}
                        message="Sunteti sigur ca vreti sa stergeti compania?"
                    />
                </>
            )}
        </div>
    );
};

export default CompanyPage;
