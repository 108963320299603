import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography, Avatar, Box, useMediaQuery, IconButton, Alert } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import {
    fetchActivityDetails,
    updateActivity,
    deleteActivity,
    fetchActivitiesForActivity
} from '../../../API/ActivityAPI';
import { fetchContactDetails } from '../../../API/ContactAPI';
import { useAuth } from '../../../API/AuthContext';
import activityImage from '../../ListPages/ActivitiesPage/note.png';
import NotesComponent from "../../../Components/Notes/NoteComponent";
import EditActivityFormDialog from '../../../Components/Activities/EditActivityFormDialog';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import ActivitiesForEntityTable from "../../../Components/Activities/ActivitiesForEntityTable";
import AddActivityForEntityFormDialog from "../../../Components/Activities/AddActivityForEntityFormDialog";
import { addActivity } from '../../../API/ActivityAPI';
import { Phone as PhoneIcon, ChatBubble as ChatBubbleIcon, CheckBox as CheckBoxIcon, Folder as FolderIcon } from '@mui/icons-material';

const ActivityPage = () => {
    const { activityId } = useParams();
    const [activity, setActivity] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [activities, setActivities] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [alert, setAlert] = useState({ message: '', severity: '', visible: false });
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (activityId) {
            loadActivityDetails();
            loadActivities();
        }
    }, [activityId]);

    const loadActivityDetails = async () => {
        try {
            const data = await fetchActivityDetails(activityId);
            setActivity(data);
            if (data.associatedEntityType === "Contact") {
                const contactData = await fetchContactDetails(data.associatedEntityId);
                if (contactData && contactData.companyName) {
                    setCompanyName(contactData.companyName);
                    setCompanyId(contactData.companyId);
                }
            }
        } catch (error) {
            console.error('Failed to load activity details:', error);
        }
    };

    const loadActivities = async () => {
        try {
            const activitiesData = await fetchActivitiesForActivity(activityId);
            setActivities(activitiesData);
        } catch (error) {
            console.error('Failed to load activities:', error);
        }
    };

    const handleEditActivity = async (updatedActivity) => {
        try {
            const updatedData = await updateActivity(activityId, updatedActivity);
            setActivity(updatedData);
            setEditDialogOpen(false);
            showAlert('Activitate editată cu succes', 'success');
        } catch (error) {
            console.error('Failed to update activity:', error);
        }
    };

    const handleDeleteActivity = async () => {
        try {
            await deleteActivity(activityId);
            navigate('/activities');
            showAlert('Activitate ștearsă cu succes', 'success');
        } catch (error) {
            console.error('Failed to delete activity:', error);
        }
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const handleAddActivityClick = () => {
        setActivityDialogOpen(true);
    };

    const handleAddActivity = async (activityData) => {
        try {
            await addActivity(activityData);
            loadActivities();
            showAlert('Activitate adăugată cu succes', 'success');
        } catch (error) {
            console.error('Failed to add activity:', error);
        } finally {
            setActivityDialogOpen(false);
        }
    };

    const showAlert = (message, severity) => {
        setAlert({ message, severity, visible: true });
        setTimeout(() => {
            setAlert({ message: '', severity: '', visible: false });
        }, 3000);
    };

    const getActivityTypeIcon = (type) => {
        switch (type) {
            case 'Apel':
                return <PhoneIcon />;
            case 'Intalnire':
                return <ChatBubbleIcon />;
            case 'Activitate':
                return <CheckBoxIcon />;
            case 'Altele':
                return <FolderIcon />;
            default:
                return null;
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? `calc(10vw)` : '0',
                marginTop: '40px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{
                    marginLeft: isMobile ? '55px' : '10px',
                    height: '100%',
                    width: `calc(100% - ${isMobile ? '65px' : '30px'})`
                }}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        {alert.visible && (
                            <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, visible: false })}>
                                {alert.message}
                            </Alert>
                        )}
                        <Box sx={{
                            padding: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                            marginBottom: 2,
                            overflow: 'hidden',
                            wordBreak: 'break-word'
                        }}>
                            {activity && (
                                <>
                                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                                        <Avatar alt={activity.name} src={activityImage} sx={{ width: 56, height: 56 }} />
                                        <Box flexGrow={1} sx={{ minWidth: '250px', maxWidth: '100%' }}>
                                            <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>{activity.name}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>ID</strong>: {activity.id}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                <strong>Tip</strong>: {getActivityTypeIcon(activity.type)} {activity.type}
                                            </Typography>
                                            {activity.subactivityEntityId && activity.subactivityEntityType && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Subactivitate ID/Tip</strong>: {activity.subactivityEntityId} / {activity.subactivityEntityType}
                                                </Typography>
                                            )}
                                            {activity.associatedEntityType === 'Company' && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Compania: </strong>
                                                    <Link to={`/companies/${activity.associatedEntityId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {activity.associatedEntityName}
                                                    </Link>
                                                </Typography>
                                            )}
                                            {activity.associatedEntityType === 'Contact' && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Contact: </strong>
                                                    <Link to={`/contacts/${activity.associatedEntityId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {activity.associatedEntityName}
                                                    </Link>
                                                </Typography>
                                            )}

                                            {activity.associatedEntityType === 'Contact' && companyName && companyId && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>de la compania </strong>
                                                    <Link to={`/companies/${companyId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {companyName}
                                                    </Link>
                                                </Typography>
                                            )}

                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Status</strong>: {activity.status}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data start</strong>: {activity.startDate}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data final</strong>: {activity.finishDate}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Responsabil</strong>: {activity.userNames.join(', ')}</Typography>
                                        </Box>
                                        {!isMobile && (
                                            <Stack direction="row" spacing={1}>
                                                <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="secondary" disabled={user.role === 'USER'}
                                                            onClick={handleDeleteClick}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton color="default" onClick={() => handleAddActivityClick()} disabled={activity.subactivityEntityId && activity.subactivityEntityType}>
                                                    <CalendarMonthIcon />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                    {isMobile && (
                                        <Stack direction="row" spacing={1} justifyContent="center" sx={{ marginTop: 2 }}>
                                            <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" disabled={user.role === 'USER'}
                                                        onClick={handleDeleteClick}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton color="default" onClick={() => handleAddActivityClick()} disabled={activity.subactivityEntityId && activity.subactivityEntityType}>
                                                <CalendarMonthIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Box>
                        {activity && (
                            <>
                                <Box sx={{ marginBottom: 2 }}>
                                    <ActivitiesForEntityTable entityId={activityId} entityType="Activity" activities={activities} />
                                </Box>
                                <Box>
                                    <NotesComponent entityId={activityId} entityType="Activity" />
                                </Box>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {activity && (
                <>
                    <EditActivityFormDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        onSave={handleEditActivity}
                        activity={activity}
                    />
                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={handleDeleteActivity}
                        message="Sunteti sigur ca vreti sa stergeti activitatea?"
                    />
                    <AddActivityForEntityFormDialog
                        open={activityDialogOpen}
                        onClose={() => setActivityDialogOpen(false)}
                        onSave={handleAddActivity}
                        subactivityEntityId={activity.id}
                        subactivityEntityType="Activity"
                        entity={activity}
                    />
                </>
            )}
        </div>
    );
};

export default ActivityPage;
