import React, { useState, useEffect } from 'react';
import {Stack, Typography, useMediaQuery} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { fetchOpportunitiesForCompany, fetchOpportunitiesForContact } from '../../API/OpportunityAPI';
import { Box } from '@mui/system';

const OpportunitiesForEntityTable = ({ entityId, entityType }) => {
    const [opportunities, setOpportunities] = useState([]);
    const [filteredOpportunities, setFilteredOpportunities] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const loadOpportunities = async () => {
            try {
                let opportunitiesData = [];
                if (entityType === "Company")
                    opportunitiesData = await fetchOpportunitiesForCompany(entityId);
                else if (entityType === "Contact")
                    opportunitiesData = await fetchOpportunitiesForContact(entityId);
                setOpportunities(opportunitiesData);
                setFilteredOpportunities(opportunitiesData);
            } catch (error) {
                console.error('Failed to load opportunities:', error);
            }
        };

        if (entityId) {
            loadOpportunities();
        }
    }, [entityId]);

    const columns = [
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/opportunities/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'value', headerName: <strong>Valoare</strong>, flex: 1, sortable: true },
        { field: 'probability', headerName: <strong>Probabilitate</strong>, flex: 1, sortable: true },
        { field: 'status', headerName: <strong>Status</strong>, flex: 1, sortable: true },
        { field: 'startDate', headerName: <strong>Data start</strong>, flex: 1, sortable: true },
        { field: 'finishDate', headerName: <strong>Data final</strong>, flex: 1, sortable: true },
        {
            field: 'userNames',
            headerName: <strong>Responsabil</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            ),
        }
    ];

    const mobileColumns = [
        {
            field: 'details',
            headerName: 'Detalii',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    <div>
                        <Link to={`/opportunities/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                            <strong>Nume:</strong> {params.row.name}
                        </Link>
                    </div>
                    <div><strong>Valoare:</strong> {params.row.value}</div>
                    <div><strong>Probabilitate:</strong> {params.row.probability}</div>
                    <div><strong>Status:</strong> {params.row.status}</div>
                    <div><strong>Data start:</strong> {params.row.startDate}</div>
                    <div><strong>Data final:</strong> {params.row.finishDate}</div>
                    <div>
                        <strong>Responsabil principal:</strong>
                        {params.row.userNames.map((name, index) => (
                            <div key={index}>{name}</div>
                        ))}
                    </div>
                </div>
            )
        }
    ];

    const calculateTotals = () => {
        const totals = {
            castigate: 0,
            inAsteptare: 0,
            pierdute: 0
        };

        opportunities.forEach(opportunity => {
            const value = parseFloat(opportunity.value) || 0;
            if (opportunity.status === 'Castigata') {
                totals.castigate += value;
            } else if (opportunity.status === 'In asteptare') {
                totals.inAsteptare += value;
            } else if (opportunity.status === 'Pierduta') {
                totals.pierdute += value;
            }
        });

        return totals;
    };

    const totals = calculateTotals();

    return (
        <div>
            <Box sx={{ width: '100%', mb: 2, backgroundColor: '#f5f5f5', borderRadius: 2}}>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems="center">
                    <Box sx={{ width: isMobile ? '100%' : '33%', textAlign: 'center', backgroundColor: '#78ef7c', color: 'black', p: 1 }}>
                        Total Castigate: {totals.castigate} RON
                    </Box>
                    <Box sx={{ width: isMobile ? '100%' : '33%', textAlign: 'center', backgroundColor: '#ece44d', color: 'black', p: 1 }}>
                        Total In asteptare: {totals.inAsteptare} RON
                    </Box>
                    <Box sx={{ width: isMobile ? '100%' : '33%', textAlign: 'center', backgroundColor: '#ee6767', color: 'black', p: 1 }}>
                        Total Pierdute: {totals.pierdute} RON
                    </Box>
                </Stack>
            </Box>

            <Typography variant="h5"><strong>Oportunitati</strong></Typography>
            <DataGrid
                rows={filteredOpportunities}
                columns={isMobile ? mobileColumns : columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                disableSelectionOnClick
                autoHeight
                disableExtendRowFullWidth
                getRowHeight={() => 'auto'}
                disableColumnMenu
                sx={{
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell--editable': {
                        outline: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid lightgray",
                        padding: "5px",
                        backgroundColor: "white",
                        width: "calc(100% - 2px)",
                    }
                }}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Pe pagina',
                    },
                    noRowsLabel: "Nici o inregistrare",
                    toolbarDensity: {
                        density: 'Density',
                        densityLabel: 'Density',
                        densityCompact: 'Compact',
                        densityStandard: 'Standard',
                        densityComfortable: 'Comfortable',
                    },
                }}
                classes={{ root: 'custom-data-grid' }}
            />
        </div>
    );
};

export default OpportunitiesForEntityTable;
