import React, { useState, useEffect } from 'react';
import {Typography, useMediaQuery} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import {
    fetchActivitiesForActivity,
    fetchActivitiesForCompany,
    fetchActivitiesForContact,
    fetchActivitiesForOpportunity,
    fetchActivitiesForTicket
} from '../../API/ActivityAPI';
import {
    ChatBubble as ChatBubbleIcon,
    CheckBox as CheckBoxIcon,
    Folder as FolderIcon,
    Phone as PhoneIcon
} from "@mui/icons-material";

const ActivitiesForEntityPage = ({ entityId, entityType }) => {
    const [activities, setActivities] = useState([]);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const loadActivities = async () => {
            try {
                let activitiesData = [];
                if (entityType === "Company")
                    activitiesData = await fetchActivitiesForCompany(entityId);
                else if (entityType === "Contact")
                    activitiesData = await fetchActivitiesForContact(entityId);
                else if (entityType === "Ticket")
                    activitiesData = await fetchActivitiesForTicket(entityId);
                else if (entityType === "Opportunity")
                    activitiesData = await fetchActivitiesForOpportunity(entityId);
                else if (entityType === "Activity")
                    activitiesData = await fetchActivitiesForActivity(entityId);
                setActivities(activitiesData);
            } catch (error) {
                console.error('Failed to load activities:', error);
            }
        };
        if (entityId) {
            loadActivities();
        }
    }, [entityId, entityType]);

    const getActivityTypeIcon = (type) => {
        switch (type) {
            case 'Apel':
                return <PhoneIcon />;
            case 'Intalnire':
                return <ChatBubbleIcon />;
            case 'Activitate':
                return <CheckBoxIcon />;
            case 'Altele':
                return <FolderIcon />;
            default:
                return null;
        }
    };

    const columns = [
        {
            field: 'type',
            headerName: <strong>Tip</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => getActivityTypeIcon(params.value)
        },
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/activities/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'status',
            headerName: <strong>Status</strong>,
            flex: 1,
            sortable: true,
        },
        {
            field: 'startDate',
            headerName: <strong>Data Start</strong>,
            flex: 1,
            sortable: true,
        },
        {
            field: 'finishDate',
            headerName: <strong>Data final</strong>,
            flex: 1,
            sortable: true,
        },
        {
            field: 'userNames',
            headerName: <strong>Responsabili</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            ),
        }
    ];

    const mobileColumns = [
        {
            field: 'details',
            headerName: 'Details',
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    <div><strong>Tip:</strong> {getActivityTypeIcon(params.row.type)}</div>
                    <div>
                        <Link to={`/activities/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                            <strong>Nume:</strong> {params.row.name}
                        </Link>
                    </div>
                    <div><strong>Status:</strong> {params.row.status}</div>
                    <div><strong>Data Start:</strong> {params.row.startDate}</div>
                    <div><strong>Data Final:</strong> {params.row.finishDate}</div>
                    <div>
                        <strong>Responsabili:</strong>
                        {params.row.userNames.map((name, index) => (
                            <div key={index}>{name}</div>
                        ))}
                    </div>
                </div>
            ),
        }
    ];

    return (
        <div>
            <Typography variant="h5"><strong>Activitati</strong></Typography>
            <DataGrid
                rows={activities}
                columns={isMobile ? mobileColumns : columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                disableSelectionOnClick
                autoHeight
                disableExtendRowFullWidth
                getRowHeight={() => 'auto'}
                disableColumnMenu
                sx={{
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell--editable': {
                        outline: 'none',
                        border: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid lightgray",
                        padding: "5px",
                        backgroundColor: "white",
                        width: "calc(100% - 2px)",
                    }
                }}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Pe pagina',
                    },
                    noRowsLabel: "Nici o inregistrare",
                    toolbarDensity: {
                        density: 'Density',
                        densityLabel: 'Density',
                        densityCompact: 'Compact',
                        densityStandard: 'Standard',
                        densityComfortable: 'Comfortable',
                    },
                }}
                classes={{ root: 'custom-data-grid' }}
            />
        </div>
    );
};

export default ActivitiesForEntityPage;
