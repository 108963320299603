import React, { useState, useEffect, useRef } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, Stack, useMediaQuery
} from '@mui/material';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { fetchUsers } from '../../API/UserAPI';
import { fetchCompanies } from '../../API/CompanyAPI';
import { useAuth } from "../../API/AuthContext";

const libraries = ["places"];

const AddContactFormDialog = ({ open, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [assignedPerson, setAssignedPerson] = useState(null);
    const [company, setCompany] = useState(null);
    const [functionRole, setFunctionRole] = useState('');
    const [position, setPosition] = useState({ lat: -34.397, lng: 150.644 });

    const [persons, setPersons] = useState([]);
    const [companies, setCompanies] = useState([]);
    const { user } = useAuth();

    const mapRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const apiKey = 'AIzaSyBtH3FvqA4ZUL43PM6jdF9iiR3D0jTJmCE';

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    useEffect(() => {
        const fetchPersonsAndCompanies = async () => {
            try {
                const personsData = await fetchUsers(user.accountId);
                setPersons(personsData);

                const companiesData = await fetchCompanies(user.accountId);
                setCompanies(companiesData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPersonsAndCompanies();
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setPosition({ lat: latitude, lng: longitude });
                if (mapRef.current) {
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                }
            });
        }
    }, []);

    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setPosition({ lat, lng });
        getGeocode({ lat, lng });
    };

    const getGeocode = ({ lat, lng }) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    console.error("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
            }
        });
    };

    const handleSave = () => {
        const contactData = {
            name,
            email,
            phoneNumber,
            address,
            function: functionRole,
            assignedPersonId: assignedPerson?.id,
            companyId: company?.id,
            latitude: position.lat,
            longitude: position.lng,
            accountId: user.accountId
        };
        onSave(contactData);
        handleClose();
    };

    const handleClose = () => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        setAddress('');
        setFunctionRole('');
        setAssignedPerson(null);
        setCompany(null);
        setPosition({ lat: -34.397, lng: 150.644 });
        onClose();
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps...</div>;
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Adauga Contact</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField label={"Nume"} value={name} onChange={(e) => setName(e.target.value)} fullWidth />

                    <Autocomplete
                        options={persons}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label={"Responsabil"} />}
                        value={assignedPerson}
                        onChange={(event, newValue) => setAssignedPerson(newValue)}
                        fullWidth
                    />

                    <Autocomplete
                        options={companies}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label={"Companie asociata"} />}
                        value={company}
                        onChange={(event, newValue) => setCompany(newValue)}
                        fullWidth
                    />

                    <TextField label={"Functie"} value={functionRole} onChange={(e) => setFunctionRole(e.target.value)} fullWidth />

                    <TextField label={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />

                    <TextField label={"Telefon"} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} fullWidth />

                    <TextField label={"Adresa"} value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />

                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '400px' }}
                        center={position}
                        zoom={15}
                        onClick={handleMapClick}
                        ref={mapRef}
                    >
                        <Marker position={position} />
                    </GoogleMap>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Anuleaza</Button>
                <Button onClick={handleSave} color="primary">Salveaza</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddContactFormDialog;
