import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography, Alert, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import backgroundImage from './background.jpg';
import { handleResetPassword } from '../../../API/PasswordAPI';

const PasswordResetPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const handleResetPasswordClick = async () => {
        try {
            const response = await handleResetPassword(email);
            setMessage('Email trimis cu succes. Va rugam sa verificati inbox-ul pentru noua parola.');
        } catch (error) {
            setMessage('Eroare la trimiterea emailului de resetare');
        }
    };

    const handleRedirectToLogin = () => {
        navigate('/login');
    };

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'rgba(5, 63, 71, 0.4)',
                    padding: isMobile ? 2 : 4,
                    borderRadius: 2,
                    margin: 5
                }}
            >
                <Typography variant={isMobile ? "h4" : "h3"} component="h1" gutterBottom sx={{ color: 'white', fontWeight: 'bold', letterSpacing: 1.5, textAlign: 'center' }}>
                    Resetare Parola
                </Typography>
                <Typography variant="body1" sx={{ color: 'white', textAlign: 'center', mb: 2 }}>
                    O noua parola generata automat va fi creata pentru autentificare si ea poate fi schimbata ulterior.
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResetPasswordClick}
                    sx={{ width: '100%', margin: '10px 0' }}
                >
                    Resetare Parola
                </Button>
                <Button
                    variant="contained"
                    onClick={handleRedirectToLogin}
                    sx={{ width: '100%', margin: '10px 0', backgroundColor: 'green', color: 'white' }}
                >
                    Inapoi la Conectare
                </Button>
                {message && <Alert severity="info" sx={{ mt: 2 }}>{message}</Alert>}
            </Container>
        </Box>
    );
};

export default PasswordResetPage;
