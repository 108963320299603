import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography, Avatar, useMediaQuery, IconButton, TextField, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, DeleteForever as DeleteForeverIcon, CalendarMonth as CalendarTodayIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Header from "../../../Components/Header/Header";
import Navbar from "../../../Components/Navbar/Navbar";
import AddTicketFormDialog from '../../../Components/Tickets/AddTicketFormDialog';
import EditTicketFormDialog from '../../../Components/Tickets/EditTicketFormDialog';
import AddActivityForEntityFormDialog from '../../../Components/Activities/AddActivityForEntityFormDialog';
import notePng from './note.png';
import { fetchTickets, addTicket, updateTicket, deleteTicket } from "../../../API/TicketAPI";
import { fetchContacts } from "../../../API/ContactAPI";
import { fetchCompanies } from "../../../API/CompanyAPI";
import { fetchUsers } from "../../../API/UserAPI";
import { useAuth } from '../../../API/AuthContext';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import { addActivity } from "../../../API/ActivityAPI";

const TicketsPage = () => {
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [ticketToDelete, setTicketToDelete] = useState(null);
    const [selectedTicketIds, setSelectedTicketIds] = useState([]);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [alert, setAlert] = useState({ message: '', severity: '', visible: false });
    const isMobile = useMediaQuery('(max-width:600px)');
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { user } = useAuth();

    const loadTickets = async () => {
        try {
            const ticketsData = await fetchTickets(user.accountId);
            const contactsData = await fetchContacts(user.accountId);
            const companiesData = await fetchCompanies(user.accountId);
            const usersData = await fetchUsers(user.accountId);

            setContacts(contactsData);
            setCompanies(companiesData);
            setUsers(usersData);

            const enhancedTickets = ticketsData.map(ticket => {
                let contactName = null;
                let contactId = null;
                let companyName = null;
                let companyId = null;

                if (ticket.associatedEntityType === 'Contact') {
                    const contact = contactsData.find(c => c.id === ticket.associatedEntityId);
                    if (contact) {
                        contactName = contact.name;
                        contactId = contact.id;
                        const company = companiesData.find(c => c.id === contact.companyId);
                        if (company) {
                            companyName = company.name;
                            companyId = company.id;
                        }
                    }
                } else if (ticket.associatedEntityType === 'Company') {
                    const company = companiesData.find(c => c.id === ticket.associatedEntityId);
                    if (company) {
                        companyName = company.name;
                        companyId = company.id;
                        const contact = contactsData.find(c => c.companyId === company.id);
                        if (contact) {
                            contactName = contact.name;
                            contactId = contact.id;
                        }
                    }
                }

                let assignedPersonNames = [];
                if (ticket.userIds && ticket.userIds.length > 0) {
                    assignedPersonNames = usersData.filter(user => ticket.userIds.includes(user.id)).map(user => user.name);
                }

                return {
                    ...ticket,
                    contactName,
                    contactId,
                    companyName,
                    companyId,
                    assignedPersonNames
                };
            });

            setTickets(enhancedTickets);
            setFilteredTickets(enhancedTickets);
        } catch (error) {
            console.error('Failed to load tickets:', error);
        }
    };

    useEffect(() => {
        if (user) {
            loadTickets();
        }
    }, [user]);

    const handleSearch = () => {
        const filtered = tickets.filter(ticket =>
            ticket.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredTickets(filtered);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (ticket) => {
        setSelectedTicket(ticket);
        setEditDialogOpen(true);
    };

    const handleDelete = async (id) => {
        try {
            await deleteTicket(id);
            loadTickets();
            showAlert('Tichet sters cu succes', 'success');
        } catch (error) {
            console.error('Failed to delete ticket:', error);
        }
    };

    const handleDeleteClick = (ticket) => {
        setTicketToDelete(ticket);
        setDeleteDialogOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteDialogOpen(true);
    };

    const handleBulkDeleteConfirm = async () => {
        try {
            await Promise.all(selectedTicketIds.map(id => deleteTicket(id)));
            loadTickets();
            showAlert('Tichete sterse cu succes', 'success');
        } catch (error) {
            console.error('Failed to delete tickets:', error);
        } finally {
            setBulkDeleteDialogOpen(false);
            setSelectedTicketIds([]);
        }
    };

    const handleAddTicket = async (ticketData) => {
        try {
            await addTicket(ticketData);
            loadTickets();
            showAlert('Tichet adaugat cu succes', 'success');
        } catch (error) {
            console.error('Failed to add ticket:', error);
        } finally {
            setAddDialogOpen(false);
        }
    };

    const handleEditTicket = async (ticketData) => {
        try {
            await updateTicket(ticketData.id, ticketData);
            loadTickets();
            showAlert('Tichet editat cu succes', 'success');
        } catch (error) {
            console.error('Failed to edit ticket:', error);
        } finally {
            setEditDialogOpen(false);
        }
    };

    const handleAddActivityClick = (ticket) => {
        setCurrentTicket(ticket);
        setActivityDialogOpen(true);
    };

    const handleAddActivity = async (activityData) => {
        try {
            await addActivity(activityData);
            showAlert('Activitate adaugata cu succes', 'success');
        } catch (error) {
            console.error('Failed to add activity:', error);
        } finally {
            setActivityDialogOpen(false);
        }
    };

    const handleCellClick = (params, event) => {
        if (params.field !== '__check__') {
            event.stopPropagation();
        }
    };

    const showAlert = (message, severity) => {
        setAlert({ message, severity, visible: true });
        setTimeout(() => {
            setAlert({ message: '', severity: '', visible: false });
        }, 3000);
    };

    const columns = [
        {
            field: 'id',
            headerName: <strong>#</strong>,
            flex: 1,
            maxWidth: 60,
            sortable: true
        },
        {
            field: 'name',
            headerName: <strong>Nume</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/tickets/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'contactName',
            headerName: <strong>Contact</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/contacts/${params.row.contactId}`} style={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'companyName',
            headerName: <strong>Companie</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/companies/${params.row.companyId}`} style={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}>
                    {params.value}
                </Link>
            ),
        },
        { field: 'priority', headerName: <strong>Prioritate</strong>, flex: 1, sortable: true },
        { field: 'creationDate', headerName: <strong>Data deschidere</strong>, flex: 1, sortable: true, },
        { field: 'finishDate', headerName: <strong>Data inchidere</strong>, flex: 1, sortable: true, },
        {
            field: 'assignedPersonNames',
            headerName: <strong>Responsabili</strong>,
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.value.map((name, index) => (
                        <div key={index}>{name}</div>
                    ))}
                </div>
            ),
        },
        { field: 'status', headerName: <strong>Status</strong>, flex: 1, sortable: true, },
        {
            field: 'actions',
            headerName: <strong>Actiuni</strong>,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton disabled={user.role == 'USER'} color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton color="default" onClick={() => handleAddActivityClick(params.row)}>
                        <CalendarTodayIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const mobileColumns = [
        {
            field: 'idmobile',
            headerName: <strong>#</strong>,
            flex: 1,
            maxWidth: 60,
            sortable: true
        },
        {
            field: 'name',
            headerName: 'Nume',
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <Link to={`/tickets/${params.row.id}`} style={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}>
                    {params.value}
                </Link>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actiuni',
            flex: 3,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={user.role == 'USER'} color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton color="default" onClick={() => handleAddActivityClick(params.row)}>
                        <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Header
                isNavbarVisible={isNavbarVisible}
                setIsNavbarVisible={setIsNavbarVisible}
                onRefreshTickets={loadTickets}
            />

            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? isMobile? '80px' : `calc(10vw)` : isMobile? '0px' : '0px',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{marginLeft: isMobile ? '25px' : '30px', height: '100%', width: 'calc(100% - 40px)'}}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <Avatar alt="s" src={notePng} />
                            <Typography variant="h5">Tichete suport</Typography>
                            {isDesktop && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)}>Adauga</Button>
                            )}
                            {isDesktop && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        id="outlined-basic"
                                        label="Cauta"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                        {isMobile && (
                            <Button variant="contained" onClick={() => setAddDialogOpen(true)} style={{ marginTop: '10px' }}>
                                Adauga
                            </Button>
                        )}
                    </div>
                    {alert.visible && (
                        <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, visible: false })}>
                            {alert.message}
                        </Alert>
                    )}
                    <div>
                        <Button
                            variant="contained"
                            endIcon={<DeleteForeverIcon />}
                            onClick={handleBulkDeleteClick}
                            size="small"
                            style={{ marginBottom: '10px', padding: '4px 8px', fontSize: '10px' }}
                        >
                            Sterge selectia
                        </Button>
                        <DataGrid
                            rows={filteredTickets}
                            columns={isMobile ? mobileColumns : columns}
                            columnVisibilityModel={{ idmobile: false, }}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                                sorting: {
                                    sortModel: [{ field: 'id', sort: 'desc' }],
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowSelectionModelChange={(newSelection) => {
                                setSelectedTicketIds(newSelection);
                            }}
                            onCellClick={handleCellClick}
                            autoHeight
                            disableExtendRowFullWidth
                            getRowHeight={() => 'auto'}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell--editable': {
                                    outline: 'none',
                                    border: 'none',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    overflow: 'visible',
                                    whiteSpace: 'normal',
                                    lineHeight: 'normal',
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid lightgray",
                                    padding: "5px",
                                    backgroundColor: "white",
                                    width: "calc(100% - 2px)",
                                }
                            }}
                            localeText={{
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Pe pagina',
                                },
                                noRowsLabel: "Nici o inregistrare",
                                toolbarDensity: {
                                    density: 'Density',
                                    densityLabel: 'Density',
                                    densityCompact: 'Compact',
                                    densityStandard: 'Standard',
                                    densityComfortable: 'Comfortable',
                                },
                            }}
                            classes={{ root: 'custom-data-grid' }}
                        />
                    </div>
                </div>
            </div>
            <AddTicketFormDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSave={handleAddTicket}
            />
            <EditTicketFormDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleEditTicket}
                ticket={selectedTicket}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={() => {
                    handleDelete(ticketToDelete.id);
                    setDeleteDialogOpen(false);
                }}
                message="Sunteti sigur ca vreti sa stergeti tichetul?"
            />
            <ConfirmationDialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                onConfirm={handleBulkDeleteConfirm}
                message="Sunteti sigur ca vreti sa stergeti tichetele selectate?"
            />
            <AddActivityForEntityFormDialog
                open={activityDialogOpen}
                onClose={() => setActivityDialogOpen(false)}
                onSave={handleAddActivity}
                subactivityEntityId={currentTicket?.id}
                subactivityEntityType="Ticket"
                entity={currentTicket}
            />
        </div>
    );
};

export default TicketsPage;
