import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { Button, Stack, Typography, Avatar, Box, useMediaQuery, IconButton, Select, MenuItem } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import { fetchContactDetails, updateContact, deleteContact } from '../../../API/ContactAPI';
import { useAuth } from '../../../API/AuthContext';
import contactImage from '../../ListPages/ContactsPage/user.png';
import TicketsForEntityTable from "../../../Components/Tickets/TicketsForEntityTable";
import EditContactFormDialog from '../../../Components/Contacts/EditContactFormDialog';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import OpportunitiesForEntityTable from "../../../Components/Opportunities/OpportunitiesForEntityTable";
import ActivitiesForEntityTable from "../../../Components/Activities/ActivitiesForEntityTable";
import NotesComponent from "../../../Components/Notes/NoteComponent";

const ContactPage = () => {
    const {contactId} = useParams();
    const [contact, setContact] = useState(null);
    const [selectedTab, setSelectedTab] = useState('notes');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const {user} = useAuth();

    useEffect(() => {
        const loadContactDetails = async () => {
            try {
                const data = await fetchContactDetails(contactId);
                setContact(data);
            } catch (error) {
                console.error('Failed to load contact details:', error);
            }
        };

        loadContactDetails();
    }, [contactId]);

    const handleEditContact = async (updatedContact) => {
        try {
            const updatedData = await updateContact(contactId, updatedContact);
            setContact(updatedData);
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Failed to update contact:', error);
        }
    };

    const handleDeleteContact = async () => {
        try {
            await deleteContact(contactId);
            navigate('/contacts');
        } catch (error) {
            console.error('Failed to delete contact:', error);
        }
    };

    const handleTabChange = (event) => {
        setSelectedTab(event.target.value);
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    return (
        <div style={{display: 'flex'}}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible}/>
            <Navbar isNavbarVisible={isNavbarVisible}/>
            <div style={{
                marginLeft: isNavbarVisible ? `calc(10vw)` : '0',
                marginTop: '40px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{
                    marginLeft: isMobile ? '55px' : '10px',
                    height: '100%',
                    width: `calc(100% - ${isMobile ? '65px' : '30px'})`
                }}>
                    <div style={{padding: '10px', margin: '10px'}}>
                        <Box sx={{
                            padding: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                            marginBottom: 2,
                            overflow: 'hidden',
                            wordBreak: 'break-word'
                        }}>
                            {contact && (
                                <>
                                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                                        <Avatar alt={contact.name} src={contactImage} sx={{width: 56, height: 56}}/>
                                        <Box flexGrow={1} sx={{minWidth: '250px', maxWidth: '100%'}}>
                                            <Typography variant="h4"
                                                        sx={{wordBreak: 'break-word'}}>{contact.name}</Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Companie</strong>:
                                                <Link to={`/companies/${contact.companyId}`}
                                                      style={{textDecoration: 'underline', color: 'blue'}}>
                                                    {contact.companyName}
                                                </Link></Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Functie</strong>: {contact.function}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Email</strong>: {contact.email}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Telefon</strong>: {contact.phoneNumber}
                                            </Typography>
                                            <Typography variant="body1"
                                                        sx={{wordBreak: 'break-word'}}><strong>Adresa</strong>: {contact.address}
                                            </Typography>
                                            {!isMobile && (
                                                    <Typography variant="body1"><strong>Responsabil</strong>: {contact.assignedPersonName}</Typography>
                                            )}
                                            {isMobile && (
                                                <>
                                                <Typography variant="body1"><strong>Responsabil</strong>:</Typography>
                                                <Typography variant="body1"
                                                            sx={{wordBreak: 'break-word'}}>{contact.assignedPersonName}</Typography>
                                                </>
                                            )}
                                        </Box>
                                        {!isMobile && (
                                            <>

                                                <Stack direction="row" spacing={1}>
                                                    <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton color="secondary" disabled={user.role === 'USER'}
                                                                onClick={handleDeleteClick}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Stack>
                                            </>
                                        )}
                                    </Stack>
                                    {isMobile && (
                                            <Stack direction="row" spacing={1} justifyContent="center"
                                                   sx={{marginTop: 2}}>
                                                <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton color="secondary" disabled={user.role === 'USER'}
                                                            onClick={handleDeleteClick}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Stack>
                                    )}
                                </>
                            )}
                        </Box>
                        <Box sx={{
                            display: isMobile ? 'none' : 'left',
                            justifyContent: 'space-between',
                            marginBottom: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                        }}>
                            <Button variant={selectedTab === 'notes' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('notes')}
                                    sx={{m: 2}}>
                                Notite
                            </Button>
                            <Button variant={selectedTab === 'activities' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('activities')}
                                    sx={{m: 2}}>
                                Activitati
                            </Button>
                            <Button variant={selectedTab === 'opportunities' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('opportunities')}
                                    sx={{m: 2}}>
                                Oportunitati
                            </Button>
                            <Button variant={selectedTab === 'tickets' ? 'contained' : 'outlined'}
                                    onClick={() => setSelectedTab('tickets')}
                                    sx={{m: 2}}>
                                Tichete
                            </Button>
                        </Box>
                        {isMobile && (
                            <Select value={selectedTab} onChange={handleTabChange} fullWidth>
                                <MenuItem value="notes">Notite</MenuItem>
                                <MenuItem value="activities">Activitati</MenuItem>
                                <MenuItem value="opportunities">Oportunitati</MenuItem>
                                <MenuItem value="tickets">Tichete</MenuItem>
                            </Select>
                        )}
                        {isMobile && (
                            <Box mt={2}/>
                        )}
                        <Box>
                            {selectedTab === 'notes' && <NotesComponent entityId={contactId} entityType="Contact"/>}
                            {selectedTab === 'activities' &&
                                <ActivitiesForEntityTable entityId={contactId} entityType="Contact"/>}
                            {selectedTab === 'opportunities' &&
                                <OpportunitiesForEntityTable entityId={contactId} entityType="Contact"/>}
                            {selectedTab === 'tickets' &&
                                <TicketsForEntityTable entityId={contactId} entityType="Contact"/>}
                        </Box>
                    </div>
                </div>
            </div>
            {contact && (
                <>
                    <EditContactFormDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        onSave={handleEditContact}
                        contact={contact}
                    />
                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={handleDeleteContact}
                        message="Sunteti sigur ca vreti sa stergeti contactul?"
                    />
                </>
            )}
        </div>
    );
};

export default ContactPage;
