import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography, Avatar, Box, useMediaQuery, IconButton, Alert } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import { fetchTicketDetails, updateTicket, deleteTicket } from '../../../API/TicketAPI';
import { fetchContactDetails } from '../../../API/ContactAPI';
import { useAuth } from '../../../API/AuthContext';
import ticketImage from '../../ListPages/TicketsPage/note.png';
import NotesComponent from "../../../Components/Notes/NoteComponent";
import EditTicketFormDialog from '../../../Components/Tickets/EditTicketFormDialog';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import ActivitiesForEntityTable from "../../../Components/Activities/ActivitiesForEntityTable";
import AddActivityForEntityFormDialog from "../../../Components/Activities/AddActivityForEntityFormDialog";
import { addActivity, fetchActivitiesForTicket } from '../../../API/ActivityAPI';

const TicketPage = () => {
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [activities, setActivities] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activityDialogOpen, setActivityDialogOpen] = useState(false);
    const [alert, setAlert] = useState({ message: '', severity: '', visible: false });
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const loadTicketDetails = async () => {
            try {
                const data = await fetchTicketDetails(ticketId);
                setTicket(data);
                if (data.associatedEntityType === "Contact") {
                    const contactData = await fetchContactDetails(data.associatedEntityId);
                    if (contactData && contactData.companyName) {
                        setCompanyName(contactData.companyName);
                        setCompanyId(contactData.companyId);
                    }
                }
            } catch (error) {
                console.error('Failed to load ticket details:', error);
            }
        };

        loadTicketDetails();
        loadActivities();
    }, [ticketId]);

    const loadActivities = async () => {
        try {
            const activitiesData = await fetchActivitiesForTicket(ticketId);
            setActivities(activitiesData);
        } catch (error) {
            console.error('Failed to load activities:', error);
        }
    };

    const handleEditTicket = async (updatedTicket) => {
        try {
            const updatedData = await updateTicket(ticketId, updatedTicket);
            setTicket(updatedData);
            setEditDialogOpen(false);
            showAlert('Tichet editat cu succes', 'success');
        } catch (error) {
            console.error('Failed to update ticket:', error);
        }
    };

    const handleDeleteTicket = async () => {
        try {
            await deleteTicket(ticketId);
            navigate('/tickets');
            showAlert('Tichet sters cu succes', 'success');
        } catch (error) {
            console.error('Failed to delete ticket:', error);
        }
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const handleAddActivityClick = () => {
        setActivityDialogOpen(true);
    };

    const handleAddActivity = async (activityData) => {
        try {
            await addActivity(activityData);
            loadActivities();
            showAlert('Activitate adaugata cu succes', 'success');
        } catch (error) {
            console.error('Failed to add activity:', error);
        } finally {
            setActivityDialogOpen(false);
        }
    };

    const showAlert = (message, severity) => {
        setAlert({ message, severity, visible: true });
        setTimeout(() => {
            setAlert({ message: '', severity: '', visible: false });
        }, 3000);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? `calc(10vw)` : '0',
                marginTop: '40px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%'
            }}>
                <div style={{
                    marginLeft: isMobile ? '55px' : '10px',
                    height: '100%',
                    width: `calc(100% - ${isMobile ? '65px' : '30px'})`
                }}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        {alert.visible && (
                            <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, visible: false })}>
                                {alert.message}
                            </Alert>
                        )}
                        <Box sx={{
                            padding: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                            marginBottom: 2,
                            overflow: 'hidden',
                            wordBreak: 'break-word'
                        }}>
                            {ticket && (
                                <>
                                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                                        <Avatar alt={ticket.name} src={ticketImage} sx={{ width: 56, height: 56 }} />
                                        <Box flexGrow={1} sx={{ minWidth: '250px', maxWidth: '100%' }}>
                                            <Typography variant="h4"
                                                        sx={{ wordBreak: 'break-word' }}>{ticket.name}</Typography>
                                            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Numarul
                                                tichetului</strong>: {ticket.id}</Typography>
                                            {ticket.associatedEntityType === 'Company' && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Compania: </strong>
                                                    <Link to={`/companies/${ticket.associatedEntityId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {ticket.associatedEntityName}
                                                    </Link>
                                                </Typography>
                                            )}
                                            {ticket.associatedEntityType === 'Contact' && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>Contact: </strong>
                                                    <Link to={`/contacts/${ticket.associatedEntityId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {ticket.associatedEntityName}
                                                    </Link>
                                                </Typography>
                                            )}

                                            {ticket.associatedEntityType === 'Contact' && companyName && companyId && (
                                                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                                                    <strong>de la compania </strong>
                                                    <Link to={`/companies/${companyId}`}
                                                          style={{ textDecoration: 'underline', color: 'blue' }}>
                                                        {companyName}
                                                    </Link>
                                                </Typography>
                                            )}

                                            <Typography variant="body1"
                                                        sx={{ wordBreak: 'break-word' }}><strong>Status</strong>: {ticket.status}
                                            </Typography>
                                            {!isMobile && (
                                                <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                                                    <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data
                                                        deschidere</strong>: {ticket.creationDate}</Typography>
                                                    <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data
                                                        inchidere</strong>: {ticket.finishDate}</Typography>
                                                </Stack>
                                            )}
                                            {isMobile && (
                                                <>
                                                    <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data
                                                        deschidere</strong>: {ticket.creationDate}</Typography>
                                                    <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><strong>Data
                                                        inchidere</strong>: {ticket.finishDate}</Typography>
                                                </>
                                            )}
                                            <Typography variant="body1"
                                                        sx={{ wordBreak: 'break-word' }}><strong>Prioritate</strong>: {ticket.priority}
                                            </Typography>

                                            {isMobile && (
                                                <>
                                                    <Typography
                                                        variant="body1"><strong>Responsabili</strong>:</Typography>
                                                    {ticket.userNames?.map((name, index) => (
                                                        <Typography key={index} variant="body1"
                                                                    sx={{ wordBreak: 'break-word' }}>{name}</Typography>
                                                    ))}
                                                </>
                                            )}
                                            {!isMobile && (
                                                <>
                                                    {ticket.userNames?.map((name, index) => (
                                                        <Typography key={index} variant="body1"
                                                                    sx={{ wordBreak: 'break-word' }}><strong>Responsabili</strong>: {name}
                                                        </Typography>
                                                    ))}
                                                </>
                                            )}
                                        </Box>
                                        {!isMobile && (
                                            <Stack direction="row" spacing={1}>
                                                <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="secondary" disabled={user.role === 'USER'}
                                                            onClick={handleDeleteClick}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton color="default" onClick={() => handleAddActivityClick()}>
                                                    <CalendarMonthIcon />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </Stack>
                                    {isMobile && (
                                        <Stack direction="row" spacing={1} justifyContent="center" sx={{ marginTop: 2 }}>
                                            <IconButton color="primary" onClick={() => setEditDialogOpen(true)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" disabled={user.role === 'USER'}
                                                        onClick={handleDeleteClick}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton color="default" onClick={() => handleAddActivityClick()}>
                                                <CalendarMonthIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Box>
                        {ticket && (
                            <>
                                <Box sx={{
                                    padding: 2,
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: 2,
                                    marginBottom: 2,
                                    overflow: 'hidden',
                                    wordBreak: 'break-word'
                                }}>
                                    <Typography variant="body1"><strong>Descriere</strong></Typography>
                                    <Typography variant="body1">{ticket.description}</Typography>
                                </Box>
                                <Box sx={{
                                    marginBottom: 2,
                                }}>
                                    <ActivitiesForEntityTable entityId={ticketId} entityType="Ticket" activities={activities} />
                                </Box>
                                <Box>
                                    <NotesComponent entityId={ticketId} entityType="Ticket" />
                                </Box>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {ticket && (
                <>
                    <EditTicketFormDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        onSave={handleEditTicket}
                        ticket={ticket}
                    />
                    <ConfirmationDialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={handleDeleteTicket}
                        message="Sunteti sigur ca vreti sa stergeti tichetul?"
                    />
                    <AddActivityForEntityFormDialog
                        open={activityDialogOpen}
                        onClose={() => setActivityDialogOpen(false)}
                        onSave={handleAddActivity}
                        subactivityEntityId={ticket.id}
                        subactivityEntityType="Ticket"
                        entity={ticket}
                    />
                </>
            )}
        </div>
    );
};

export default TicketPage;
