import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/notes';

export const fetchNotesForEntity = async (entityId, entityType) => {
    try {
        const response = await axios.get(`${BASE_URL}/${entityType}/${entityId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching notes:', error);
        throw error;
    }
};


export const createNote = async (noteData) => {
    const response = await axios.post(BASE_URL, noteData);
    return response.data;
};

export const updateNote = async (id, noteData) => {
    const response = await axios.put(`${BASE_URL}/${id}`, noteData);
    return response.data;
};

export const deleteNote = async (id) => {
    await axios.delete(`${BASE_URL}/${id}`);
};

export const deleteFileFromNote = async (fileName) => {
    await axios.delete(`${BASE_URL}/files/${fileName}`);
};

export const uploadFiles = async (noteId, formData) => {
    await axios.post(`${BASE_URL}/${noteId}/files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const downloadNoteFile = async (noteId, filename) => {
    const response = await axios.get(`${BASE_URL}/${noteId}/files/${filename}`, {
        responseType: 'blob'
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
};
