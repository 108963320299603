import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography, Avatar, useMediaQuery, IconButton, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import Header from '../../../Components/Header/Header';
import Navbar from '../../../Components/Navbar/Navbar';
import AddProductFormDialog from '../../../Components/Products/AddProductFormDialog';
import EditProductFormDialog from '../../../Components/Products/EditProductFormDialog';
import productImage from './product.png';
import { fetchProducts, addProduct, updateProduct, deleteProduct } from '../../../API/ProductAPI';
import { useAuth } from '../../../API/AuthContext';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import settingsImage from "../../OtherPages/AccountPage/settings-gear2.png";
import { useNavigate } from "react-router-dom";

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isDesktop = useMediaQuery('(min-width:600px)');
    const { user } = useAuth();
    const navigate = useNavigate();

    const loadProducts = async () => {
        try {
            const data = await fetchProducts(user.accountId);
            setProducts(data);
            setFilteredProducts(data);
        } catch (error) {
            console.error('Failed to load products:', error);
        }
    };

    useEffect(() => {
        if (user) {
            loadProducts();
        }
    }, [user]);

    const handleSearch = () => {
        const query = searchQuery.toLowerCase();
        const filtered = products.filter(product =>
            product.name.toLowerCase().includes(query) ||
            product.productCode.toLowerCase().includes(query)
        );
        setFilteredProducts(filtered);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleEdit = (product) => {
        setSelectedProduct(product);
        setEditDialogOpen(true);
    };

    const handleDelete = (id) => {
        deleteProduct(id).then(loadProducts).catch(console.error);
    };

    const handleDeleteClick = (product) => {
        setProductToDelete(product);
        setDeleteDialogOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteDialogOpen(true);
    };

    const handleBulkDeleteConfirm = () => {
        Promise.all(selectedProductIds.map(id => deleteProduct(id)))
            .then(loadProducts)
            .catch(console.error)
            .finally(() => {
                setBulkDeleteDialogOpen(false);
                setSelectedProductIds([]);
            });
    };

    const handleAddProduct = (productData) => {
        addProduct(productData).then(loadProducts).catch(console.error);
        setAddDialogOpen(false);
    };

    const handleEditProduct = (productData) => {
        updateProduct(productData.id, productData).then(loadProducts).catch(console.error);
        setEditDialogOpen(false);
    };

    const handleCellClick = (params, event) => {
        if (params.field !== '__check__') {
            event.stopPropagation();
        }
    };

    const columns = [
        { field: 'name', headerName: <strong>Nume</strong>, flex: 2, sortable: true },
        { field: 'description', headerName: <strong>Descriere</strong>, flex: 3, sortable: true },
        { field: 'productCode', headerName: <strong>Cod produs</strong>, flex: 2, sortable: true },
        { field: 'price', headerName: <strong>Pret unitar</strong>, flex: 2, sortable: true },
        { field: 'currency', headerName: <strong>Valuta</strong>, flex: 1, sortable: true },
        { field: 'unitOfMeasure', headerName: <strong>Unitate de masura</strong>, flex: 2, sortable: true },
        { field: 'tax', headerName: <strong>TVA%</strong>, flex: 1, sortable: true },
        { field: 'status', headerName: <strong>Stoc</strong>, flex: 2, sortable: true },
        {
            field: 'actions',
            headerName: <strong>Actiuni</strong>,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const mobileColumns = [
        {
            field: 'name',
            headerName: 'Nume',
            flex: 1,
            sortable: true,
        },
        {
            field: 'actions',
            headerName: 'Actiuni',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" spacing={0} alignItems="center">
                    <IconButton color="primary" onClick={() => handleEdit(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex' }}>
            <Header isNavbarVisible={isNavbarVisible} setIsNavbarVisible={setIsNavbarVisible} />
            <Navbar isNavbarVisible={isNavbarVisible} />
            <div style={{
                marginLeft: isNavbarVisible ? isMobile? '80px' : `calc(10vw)` : isMobile? '0px' : '0px',
                marginTop: '50px',
                transition: 'margin-left 0.2s',
                height: 'calc(100vh - 100px)',
                overflowY: 'auto',
                width: '100%',
            }}>
                <div style={{marginLeft: isMobile ? '25px' : '30px', height: '100%', width: 'calc(100% - 40px)'}}>
                    <div style={{ padding: '10px', margin: '10px' }}>
                        <Stack spacing={2} direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'}>
                            <Stack spacing={2} direction="row" alignItems="center">
                                <Avatar alt="s" src={settingsImage} onClick={() => navigate('/settings')} style={{ cursor: 'pointer' }} />
                                <Typography variant="h4">/</Typography>
                                <Avatar alt="s" src={productImage} />
                                <Typography variant="h5">Produse</Typography>
                            </Stack>
                            {(user.role === 'ADMIN' || user.role === 'OWNER') && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)}>Adauga</Button>
                            )}
                            {(user.role === 'USER') && (
                                <Button variant="contained" onClick={() => setAddDialogOpen(true)} disabled>Adauga</Button>
                            )}
                            {isDesktop && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        id="outlined-basic"
                                        label="Cauta"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        size="small"
                                    />
                                    <IconButton color="primary" onClick={handleSearch}>
                                        <SearchIcon />
                                    </IconButton>
                                </Stack>
                            )}
                        </Stack>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            endIcon={<DeleteForeverIcon />}
                            onClick={handleBulkDeleteClick}
                            size="small"
                            style={{ marginBottom: '10px', padding: '4px 8px', fontSize: '10px' }}
                        >
                            Sterge selectia
                        </Button>
                        <DataGrid
                            rows={filteredProducts}
                            columns={isMobile ? mobileColumns : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                                sorting: {
                                    sortModel: [{ field: 'name', sort: 'asc' }],
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            onRowSelectionModelChange={(newSelection) => {
                                setSelectedProductIds(newSelection);
                            }}
                            getRowHeight={() => 'auto'}
                            onCellClick={handleCellClick}
                            autoHeight
                            disableExtendRowFullWidth
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell': {
                                    border: 'none',
                                },
                                '& .MuiDataGrid-cell--editable': {
                                    border: 'none',
                                },
                                "& .MuiDataGrid-row": {
                                    borderBottom: "1px solid lightgray",
                                    padding: "5px",
                                    backgroundColor: "white",
                                    width: "calc(100% - 2px)",
                                }
                            }}
                            localeText={{
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Pe pagina',
                                },
                                noRowsLabel: "Nici o inregistrare",
                                toolbarDensity: {
                                    density: 'Density',
                                    densityLabel: 'Density',
                                    densityCompact: 'Compact',
                                    densityStandard: 'Standard',
                                    densityComfortable: 'Comfortable',
                                },
                            }}
                            classes={{ root: 'custom-data-grid' }}
                        />
                    </div>
                </div>
            </div>
            <AddProductFormDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSave={handleAddProduct}
            />
            <EditProductFormDialog
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleEditProduct}
                product={selectedProduct}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={() => {
                    handleDelete(productToDelete.id);
                    setDeleteDialogOpen(false);
                }}
                message="Sunteti sigur ca vreti sa stergeti produsul?"
            />
            <ConfirmationDialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                onConfirm={handleBulkDeleteConfirm}
                message="Sunteti sigur ca vreti sa stergeti produsele selectate?"
            />
        </div>
    );
};

export default ProductsPage;
