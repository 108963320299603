import React, { useEffect, useState } from 'react';
import AuthService from '../../../API/AuthService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../API/AuthContext";
import { Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography, Alert, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import backgroundImage from './try28.jpg';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [company, setCompany] = useState('');
    const navigate = useNavigate();
    const { isAuthenticated, login } = useAuth();
    const [termsandcondition, settermsandcondition] = useState(false);
    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [password2Valid, setPassword2Valid] = useState(true);
    const [companyValid, setCompanyValid] = useState(true);
    const [termsChecked, setTermsChecked] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleLoginClick = () => {
        if (isAuthenticated) {
            navigate('/home');
        } else {
            navigate('/login');
        }
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setNameValid(!!newName.trim());
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail));
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordValid(newPassword.length >= 8 && /\d/.test(newPassword));
    };

    const handlePassword2Change = (e) => {
        const newPassword2 = e.target.value;
        setPassword2(newPassword2);
        setPassword2Valid(newPassword2 === password);
    };

    const handleCompanyChange = (e) => {
        const newCompany = e.target.value;
        setCompany(newCompany);
        setCompanyValid(!!newCompany.trim());
    };

    const handleRegister = async () => {
        setTermsChecked(termsandcondition);

        setErrorMessage('');

        if (!name.trim() || !email.trim() || !password.trim() || !password2.trim() || !company.trim()) {
            setErrorMessage('Completati toate campurile.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Adresa de email invalida.');
            return;
        }

        if (password !== password2) {
            setErrorMessage('Parolele nu se potrivesc.');
            return;
        }

        if (password.length < 8 || !/\d/.test(password)) {
            setErrorMessage('Parola trebuie sa aiba minim 7 litere si o cifra.');
            return;
        }

        if (!termsChecked || !nameValid || !passwordValid || !password2Valid || !companyValid)
            return;

        try {
            const userData = { name, email, password, company_name: company };
            const response = await AuthService.signUp(userData);
            if (response.token) {
                login(response.token, { userId: response.userId, userName: response.userName, accountId: response.accountId, role: response.role }, termsandcondition);
                console.log('Registration successful:', response);
                navigate('/home');
            }
        } catch (err) {
            console.log('Registration error:', err);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleRegister();
        }
    };

    return (
        <Box
            sx={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'rgba(5, 63, 71, 0.4)',
                    padding: isMobile ? 2 : 4,
                    borderRadius: 2,
                    margin: 5
                }}
            >
                <Typography variant={isMobile ? "h4" : "h3"} component="h1" gutterBottom sx={{ color: 'white', fontWeight: 'bold', letterSpacing: 1.5, textAlign: 'center' }}>
                    TTracker
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Nume*"
                    value={name}
                    onChange={handleNameChange}
                    onKeyDown={handleKeyDown}
                    error={!nameValid}
                    helperText={!nameValid && "Numele este obligatoriu"}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email*"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyDown}
                    error={!emailValid}
                    helperText={!emailValid && "Email invalid"}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Parola*"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                    error={!passwordValid}
                    helperText={!passwordValid && "Parola trebuie sa aiba minim 7 litere si o cifra"}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Repeta parola*"
                    type="password"
                    value={password2}
                    onChange={handlePassword2Change}
                    onKeyDown={handleKeyDown}
                    error={!password2Valid}
                    helperText={!password2Valid && "Parolele nu se potrivesc"}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Compania*"
                    value={company}
                    onChange={handleCompanyChange}
                    onKeyDown={handleKeyDown}
                    error={!companyValid}
                    helperText={!companyValid && "Compania este obligatorie"}
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={termsandcondition}
                            onChange={e => settermsandcondition(e.target.checked)}
                            sx={{ color: 'white' }}
                        />
                    }
                    label={<Typography sx={{ color: 'white' }}>Sunt de acord cu <span style={{ color: "#2342A6FF", cursor: 'pointer' }}>Termenii si Conditiile Serviciului</span></Typography>}
                />
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRegister}
                    sx={{ width: '100%', margin: '10px 0' }}
                >
                    Creati cont
                </Button>
                <hr style={{ width: '100%', borderTop: '1px solid white', marginBottom: 2 }} />
                <Typography variant="h6" sx={{ color: 'gainsboro', paddingTop: '10px', paddingBottom: '10px' }}>
                    Aveti deja cont?
                </Typography>
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleLoginClick}
                    sx={{ width: '100%' }}
                >
                    Conectare
                </Button>
            </Container>
        </Box>
    );
};

export default Register;
