import React, { useState, useEffect, useRef } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, Stack, useMediaQuery, Typography, Select, MenuItem
} from '@mui/material';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { fetchUsers } from '../../API/UserAPI';
import { useAuth } from "../../API/AuthContext";

const libraries = ["places"];

const AddCompanyFormDialog = ({ open, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [assignedPersons, setAssignedPersons] = useState([]);
    const [cuicode, setcuicode] = useState('');
    const [companyGroup, setCompanyGroup] = useState('');
    const [position, setPosition] = useState({ lat: -34.397, lng: 150.644 });

    const [persons, setPersons] = useState([]);
    const { user } = useAuth();

    const mapRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const apiKey = 'AIzaSyBtH3FvqA4ZUL43PM6jdF9iiR3D0jTJmCE';

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    useEffect(() => {
        const fetchPersons = async () => {
            try {
                const personsData = await fetchUsers(user.accountId);
                setPersons(personsData);
            } catch (error) {
                console.error('Error fetching persons:', error);
            }
        };

        fetchPersons();
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setPosition({ lat: latitude, lng: longitude });
                if (mapRef.current) {
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                }
            });
        }
    }, []);

    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setPosition({ lat, lng });
        getGeocode({ lat, lng });
    };

    const getGeocode = ({ lat, lng }) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    console.error("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
            }
        });
    };

    const handleSave = () => {
        const companyData = {
            name,
            email,
            phoneNumber,
            address,
            cuicode,
            companyGroup,
            assignedPersonIds: assignedPersons.map(person => person.id),
            latitude: position.lat,
            longitude: position.lng,
            accountId: user.accountId
        };
        onSave(companyData);
        handleClose();
    };

    const handleClose = () => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        setAddress('');
        setAssignedPersons([]);
        setcuicode('');
        setCompanyGroup('');
        setPosition({ lat: -34.397, lng: 150.644 });
        onClose();
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps...</div>;
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Adauga compania</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField label={"Nume"} value={name} onChange={(e) => setName(e.target.value)} fullWidth />

                    <Autocomplete
                        multiple
                        options={persons}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label={"Persoane responsabile"} />}
                        value={assignedPersons}
                        onChange={(event, newValue) => setAssignedPersons(newValue)}
                        fullWidth
                    />

                    <TextField label={"Cod CUI"} value={cuicode} onChange={(e) => setcuicode(e.target.value)} fullWidth />

                    <TextField label={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />

                    <TextField label={"Telefon"} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} fullWidth />

                    <Select
                        value={companyGroup}
                        onChange={(e) => setCompanyGroup(e.target.value)}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Selecteaza grupul</MenuItem>
                        <MenuItem value="Client">Client</MenuItem>
                        <MenuItem value="Furnizor">Furnizor</MenuItem>
                        <MenuItem value="Client-Furnizor">Client-Furnizor</MenuItem>
                        <MenuItem value="Blacklist">Lista Neagra</MenuItem>
                    </Select>

                    <TextField label={"Adresa"} value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />

                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '400px' }}
                        center={position}
                        zoom={15}
                        onClick={handleMapClick}
                        ref={mapRef}
                    >
                        <Marker position={position} />
                    </GoogleMap>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Anuleaza</Button>
                <Button onClick={handleSave} color="primary">Salveaza</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCompanyFormDialog;
