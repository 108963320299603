import axios from 'axios';

const BASE_URL = 'https://api.crminternal.mediakom.ro:8181/password';

export const handleResetPassword = async (email) => {
    try {
        const response = await axios.post(`${BASE_URL}/reset-password`, email, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error reseting password:', error);
        throw error;
    }
};
